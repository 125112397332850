import React, { useState } from 'react';
import ConfigurationDetails from './order/ConfigurationDetails';
import FormattedPrice from './common/FormattedPrice';
import styles from '../styles/components/OrderListItem.module.scss';
import { convertCentsToDollars } from '../utils/componentUtils';
import { useTranslation } from 'react-i18next';

const OrderListItem = ({ polygon, showCheckbox, isChecked = false, 
  disableCheckbox = false, lineOrderCheckChanged, callbackData }) => {
  const [show, setShow] = useState(false);
  const expandCollapseHandler = () => setShow(!show);
  const { t } = useTranslation();

  const checkChangeEventHandler = (event) => {
    lineOrderCheckChanged(event.target.checked, callbackData);
  };

  const checkboxContent = showCheckbox && (
    <div className={styles.check}>
      <input 
        type="checkbox" 
        checked={isChecked}
        className={disableCheckbox ? styles.disabled : ''} disabled={disableCheckbox}
        onChange={checkChangeEventHandler} />
    </div>
  );

  const manufactureCost = polygon.manufactureCost ? polygon.manufactureCost.prices.totalPrice : 0;
  const itemContent = polygon ? (
    <div className={styles.container}>
      <div className={styles.containerWrapper}>
        <div className={styles.leftContainer}>
          <div className={styles.header}>
            {checkboxContent}
            <div className={styles.thumbnail} style={{backgroundImage: `url(${polygon.imageUrl})`}}>
            </div>
            <div className={styles.info}>
              <span className="bolder">{polygon.extendedTitle}</span>
              <span className="bolder">{t('product.quantity')}{`: ${polygon.quantity || 1}x`}</span>
            </div>
          </div>
          
          <div className={styles.details}>
            <div className={`collapse ${show ? 'open' : ''}`}>
              <ConfigurationDetails polygon={polygon} />
            </div>
          </div>
        </div>
        
        <div className={styles.rightContainer}>
          <div className={styles.expend}>
            <img
              className={show ? styles.expended : ''}
              onClick={expandCollapseHandler}
              src="../assets/details.png"
              alt="arrow-down" />
          </div>
          <div className={`${styles.price} bold`}>
            <FormattedPrice price={convertCentsToDollars(polygon.prices.totalPrice + manufactureCost)} />
          </div>
        </div>
      </div>
    </div>
  ): null;

  return itemContent;
};

export default OrderListItem;
