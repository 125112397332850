import { useState } from 'react';
import styles from '../../styles/components/common/Accordion.module.scss';

const Accordion = ({ title, itemsComponent, isOpen = false, checkboxProps }) => {
  const [showDetails, setShowDetails] = useState(isOpen);

  const toggleDetailsHandler = () => setShowDetails(!showDetails);

  const checkChangeEventHandler = (event) => {
    checkboxProps.onChangeHandler?.(event.target.checked, checkboxProps.callbackData);
  };

  function getCheckboxContent() {
    if (checkboxProps) {
      const disabledClass = checkboxProps.disabled ? styles.disabled : '';
      return (
        <div className={`${styles.checkbox} ${styles.rightSpace}`}>
          <input 
            type='checkbox' 
            checked={checkboxProps.checked}
            className={disabledClass}
            disabled={checkboxProps.disabled}
            onChange={checkChangeEventHandler} />
        </div>
      );
    }
    return null;
  }

  return (
    <div className={styles.listBox}>
      <div className='flexRow'>
        {getCheckboxContent()}
        <span className={styles.listItemTitle} onClick={toggleDetailsHandler}>
          {title}
        </span>
      </div>
      <div className={`collapse ${showDetails ? 'open' : ''}`}>
        {itemsComponent}
      </div>
    </div>
  );
};
export default Accordion;
