import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { getWindowDecorationPLPUrl } from '../utils/windowDecorationUtils';
import styles from '../styles/components/NavigationButtons.module.scss';

const Button = () => {
  let history = useHistory();
  const { t } = useTranslation();

  const redirectToPLP = async () => {
    window.location.href = await getWindowDecorationPLPUrl();
  };

  const redirectToRoute = (route) => {
    if (route.toLowerCase() !== window.location.pathname.toLocaleLowerCase()) {
      history.push(route);
    }
  };

  return (
    <div className={styles.buttons}>
      <button className={styles.btn} onClick={redirectToPLP}>
        <div className={styles.content}>
          <p className="bold">{t('order')}</p>
          <p className="bold">{t('configure')}</p>
        </div>
      </button>
      <button className={styles.btn} onClick={() => redirectToRoute('/search')}>
        <div className={styles.content}>
          <p className="bold">{t('order')}</p>
          <p className="bold">{t('search')}</p>
        </div>
      </button>
      <button className={styles.btn} onClick={() => redirectToRoute('/store/orders')}>
        <div className={styles.content}>
          <p className="bold">{t('store-orders')}</p>
        </div>
      </button>
    </div>
  );
};

export default Button;
