import styles from '../styles/components/autoSuggestion.module.scss';

const AutoSuggestionItem = ({text, item}) => {

  function getText() {
    if (item && item.position >= 0) {
      return <div>
        <span>{item.preHighlightedText}</span>
        <span className={styles.highlight}>{item.highlightedText}</span>
        <span>{item.postHighlightedText}</span>
      </div>;
    }
    return text;
  }

  return (
    <div className={styles.item}>
      <div className={styles.itemWrapper}>
        {getText()}
      </div>
    </div>
  );
};
export default AutoSuggestionItem;
