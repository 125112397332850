import { POST } from './ApiService';

const baseUrl = '/v1/shipments';

export default class ShipmentService {
  static async markOrderLinesAsDelivered(payload, callback, callbackError, onTheFlyData) {
    POST({
      url: `${baseUrl}/confirm`,
      token: true, 
      body: payload,
    }, callback, callbackError, onTheFlyData
    );
  }
}
