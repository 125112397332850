import { generateId } from '../utils/componentUtils';
import { useState } from 'react';
import AutoSuggestion from './AutoSuggestion';
import styles from '../styles/components/TextInput.module.scss';

const TextInput = ({data}) => {
  const elementId = generateId('textInput_');
  const [inputValue, setInputValue] = useState('');
  const [isAutosuggestionVisible, setIsAutoSuggestionVisible] = useState(false);

  function applyInputValueHandler(event) {
    if (event.type === 'keyup' && event.keyCode !== 13) {
      setIsAutoSuggestionVisible(true);
      return;
    }
    
    setIsAutoSuggestionVisible(false);
    return data.callback?.(inputValue, data);
  }

  function onItemSelected(item) {
    data.callback?.(item, data);
    setIsAutoSuggestionVisible(false);
    setInputValue(item);
  }

  function onChangeHadler(event) {
    setInputValue(event.target.value);
    if (!event.target.value) {
      data.callback?.(event.target.value, data);
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.suggestionContainer}>
        <input id={elementId} className={`${styles.input} customerName_input`} type="text"
          value={inputValue} onChange={onChangeHadler} onKeyUp={applyInputValueHandler}></input>
        {
          inputValue && data.isAutoSuggestion && isAutosuggestionVisible
            ? <AutoSuggestion
              key={'autoSuggestion'} items={data.autoSuggestionItems} 
              value={inputValue} callback={onItemSelected} />
            : null
        }
      </div>
      {
        data.isWithButton
          ? <input type='button' className={styles.button} value={data.buttonText} onClick={applyInputValueHandler} />
          : null
      }
    </div>
  );
};

export default TextInput;
