import React from 'react';
import styles from '../styles/components/Table.module.scss';

const Table = ({header, itemsArray, isHorizontal = false, options}) => {
  fillOptionsWithDefaultValues();

  return (
    <table>
      <tbody>
        {isHorizontal
          ? getHorizontalView()
          : getVerticalView()}
      </tbody>
    </table>
  );

  function getHorizontalView() {
    const rows = [[]];

    if (header) {
      header.forEach((headerItem, index) => {
        if (rows[index] === undefined) {
          rows[index] = [];
        }
        const grow = options.growSize === index ? styles.grow : '';
        rows[index].push(<th className={`${grow}`}>{headerItem}</th>);
      });
    }

    itemsArray.forEach((items) => {
      items.forEach((item, index) => {
        if (rows[index] === undefined) {
          rows[index] = [];
        } 

        const grow = options.growSize === index ? styles.grow : '';
        rows[index].push(<td className={`${grow}`}>{item}</td>);
      });
    });

    return rows.map((row) => {
      return <tr>{row}</tr>;
    });
  }

  function getVerticalView() {
    const borderCss = options?.border ? styles.border : '';
    let headerHtml = '';
    if (header) {
      headerHtml = header.map((headerItem, index) => {
        const grow = options.growSize === index ? styles.grow : '';
        return <th className={`${borderCss} ${grow}`}>{headerItem}</th>;
      });
    }

    let bodyHtml = itemsArray.map((items) => {
      const rowHtml = items.map((item, index) => {
        const grow = options.growSize === index ? styles.grow : '';
        return <td className={`${borderCss} ${grow}`}>{item}</td>;
      });
      return <tr>{rowHtml}</tr>;
    });
    return (
      <>
        <tr>{headerHtml}</tr>
        {bodyHtml}
      </>
    );
  }

  function fillOptionsWithDefaultValues() {
    const defaultOptions = {
      border: true,
      growSize: null,
    };

    options = {
      ...defaultOptions,
      ...options,
    };
  }
};

export default Table;
