import React from 'react';
import styles from '../../styles/components/Dots.module.scss';

const Dots = () => {
  return (
    <div className={styles.dots}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Dots;
