import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from '../styles/pages/NotFound.module.scss';
import '../styles/pages/styles.scss';

function NotFound() {
  let location = useLocation();
  const { t } = useTranslation();

  function goToHomepage() {
    window.open('/', '_self');
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.errorCode}>
          404
        </div>
        <div className={styles.errorMessage}>
          <div>
            {t('errors.pageNotFound.prefix')}
            &nbsp;<span className={styles.page}>{location.pathname}</span>&nbsp;
            {t('errors.pageNotFound.suffix')}
          </div>
        </div>
        <div className={styles.backBottom}>
          <div className={styles.wrapper}>
            <button onClick={goToHomepage}>
              {`< ${t('global.back')}`}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
