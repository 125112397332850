import { CURTAIN_MANUFACTURE_COST_PREFIX } from '../utils/constants';
import { GET, POST } from './ApiService';

const baseUrl = '/v1/orders';

export default class OrderService {
  static async get(orderId, callback, callbackError, onTheFlyData) {
    GET({
      url: `${baseUrl}/${orderId}`,
      token: true,
    }, callback, callbackError, onTheFlyData);
  };

  static async getOrderDetails(orderId, callback, callbackError, onTheFlyData) {
    GET({
      url: `${baseUrl}/${orderId}/details`,
      token: true,
    }, callback, callbackError, onTheFlyData);
  };

  static async confirm(orderId, callback, callbackError, onTheFlyData) {
    POST({
      url: `${baseUrl}/${orderId}/confirm`,
      token: true,
      body: null,
    }, callback, callbackError, onTheFlyData);
  }
  
  static async getOrderDetailsByShipmentId(shipmentId, callback, callbackError, onTheFlyData) {
    GET({
      url: `${baseUrl}/shipment/${shipmentId}/orderDetails`,
      token: true,
    }, callback, callbackError, onTheFlyData);
  }

  static async addCustomerInfo(orderId, customerInfo, callback, callbackError, onTheFlyData) {
    POST({
      url: `${baseUrl}/${orderId}/add-customer-info`,
      token: true,
      body: customerInfo,
    }, callback, callbackError, onTheFlyData);
  }

  static async getISAOrder(orderId, callback, callbackError, onTheFlyData) {
    GET({
      url: `${baseUrl}/isa/${orderId}`,
      token: true,
    }, callback, callbackError, onTheFlyData);
  };

  static getOrderWithExtendedPolygons(order) {
    if (!order || !order.polygons || !order.polygons.length) {
      return order;
    }

    const polygons = [];
    const polygonMap = {};
    order.polygons.forEach((polygon) => {
      if (!polygonMap[polygon.id]) {
        polygonMap[polygon.id] = {
          polygon: {},
        };
      }

      if (polygon.productId.startsWith(CURTAIN_MANUFACTURE_COST_PREFIX)) {
        polygonMap[polygon.id].manufactureCost = polygon;
      } else {
        polygonMap[polygon.id].polygon = polygon;
      }
    });
    
    Object.keys(polygonMap).forEach(polygon => {
      polygons.push({
        ...polygonMap[polygon].polygon,
        manufactureCost: polygonMap[polygon].manufactureCost,
      });
    });
      
    return {
      ...order,
      polygons 
    };
  }
};
