import { useEffect, useState } from 'react';
import OrderLookupContent from '../components/lookup/OrderLookupContent';
import { parseUrlHashParams, updateUrlHashParam } from '../utils/urlUtils';
import { ORDER_ID_SEARCH_PARAM } from '../utils/constants';

function OrderLookup() {
  const hashParams = parseUrlHashParams();
  const [orderId, setOrderId] = useState(hashParams[ORDER_ID_SEARCH_PARAM] || '');

  useEffect(() => {
    window.addEventListener('hashchange', onHashChangeHandler, true);
    return () => {
      window.removeEventListener('hashchange', onHashChangeHandler, true);
    };
  });

  function onHashChangeHandler() {
    const hashParams = parseUrlHashParams();
    setOrderId(hashParams[ORDER_ID_SEARCH_PARAM]);
  };

  const updateOrderIdHashParam = (orderId) => {
    updateUrlHashParam(ORDER_ID_SEARCH_PARAM, orderId);
  };

  return (
    <OrderLookupContent 
      orderId={orderId} 
      updateOrderIdHashParam={updateOrderIdHashParam} 
    />
  );
}

export default OrderLookup;
