import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import Printer from '../printer/Printer';
import styles from '../../styles/components/ComponentPrinter.module.scss';

const ComponentPrinter = ({componentToPrint, redirectUrl}) => {
  const componentRef = useRef();

  function redirectPage() {
    if (redirectUrl) {
      window.open(redirectUrl, '_self');
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.buttonWrapper}>
        <ReactToPrint 
          trigger={() => <button className={`${styles.button} hideOnPrint`}>Print</button>}
          content={() => componentRef.current}
          onAfterPrint={redirectPage}
        />
      </div>
      <Printer ref={componentRef} component={componentToPrint} />
    </div>
  );
};
export default ComponentPrinter;
