import { getToken } from '../utils/authUtils';

export const GET = async(requestConfig, callback, callbackError, onTheFlyData) => {
  requestConfig.method = 'GET';
  _fetch(requestConfig, callback, callbackError, onTheFlyData);
};

export const POST = async (requestConfig, callback, callbackError, onTheFlyData) => {
  requestConfig.method = 'POST';
  _fetch(requestConfig, callback, callbackError, onTheFlyData);
};

export const PUT = async (requestConfig, callback, callbackError, onTheFlyData) => {
  requestConfig.method = 'PUT';
  _fetch(requestConfig, callback, callbackError, onTheFlyData);
};

async function _fetch(requestConfig, callback, callbackError, onTheFlyData) {
  const request = await _extendHeaders(requestConfig);
  try {
    const response = await fetch(requestConfig.url, request);
    if (!response.ok) {
      return callbackError?.(response, onTheFlyData);
    }

    let data = null;
    try {
      data = await response.json();
    } catch(e) {
    }
    return callback?.(data, onTheFlyData);
  } catch (error) {
    return callbackError?.(error, onTheFlyData);
  }
};

async function _extendHeaders(requestConfig) {
  const innerHeader = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    ...requestConfig.headers
  };

  const request = {
    method: requestConfig.method,
    headers: innerHeader,
  };

  if (requestConfig.token) {
    request.headers.Authorization = 'Bearer ' + await getToken();
  }

  if (requestConfig.body) {
    request.body = JSON.stringify(requestConfig.body);
  }

  return request;
};
