import { roundMoney } from '../../utils/componentUtils';

const FormattedPrice = ({ price, isRedColor }) => {
  // we ensure we use a rounded money amount for formatting to ensure we will display an amount rounded to cents.
  price = roundMoney(Number(price));

  const [formattedPriceMain, formattedPriceDecimal] = price.toString().split('.');

  return getHtmlContent();

  function getDecimalNumber() {
    const formattedPart = (formattedPriceDecimal || 0).toString().padEnd(2, '0');
    return formattedPart === '00' ? '-' : formattedPart;
  }

  function getHtmlContent() {
    if (formattedPriceMain === '0' && formattedPriceDecimal === undefined) {
      return '-';
    }

    return (
      <div style={isRedColor ? { color: 'red' } : {}}>
        {formattedPriceMain + '.'}
        <sup>{getDecimalNumber()}</sup>
      </div>
    );
  }
};

export default FormattedPrice;
