import { useState } from 'react';
import { generateId } from '../utils/componentUtils';
import styles from '../styles/components/DateInput.module.scss';

const DateInput = ({ data }) => {
  const elementId = generateId('textInput_');
  const [inputValue, setinputValue] = useState();

  function onChangeHadler(event) {
    setinputValue(event.target.value);
    if (!event.target.value) {
      data.callback?.(event.target.value, data);
    }
  }

  function applyInputValueHandler(event) {
    if (event.type === 'keyup' && event.keyCode !== 13) {
      return;
    }
    data.callback?.(inputValue, data);
  }

  return (
    <div className={styles.container}>
      <input id={elementId} className={styles.input} type="date" value={inputValue} 
        onChange={onChangeHadler} 
        onKeyUp={applyInputValueHandler}>
      </input>

      {data.isWithButton ? (
        <input type="button" className={styles.button} value={data.buttonText} onClick={applyInputValueHandler} />
      ) : null}
    </div>
  );
};
export default DateInput;
