import appConfig from '../config/app-config';
import { getCountryCode, getLanguage, getStoreId } from './authUtils';
import { COUNTRY_CODE_BE, COUNTRY_CODE_FR } from './constants';

export const getWindowDecorationPLPUrl = async () => {
  const urlString = getRedirectUrl(await getCountryCode(), await getLanguage());
  const url = new URL(urlString);
  url.searchParams.set('storeId', getStoreId());
  url.searchParams.set('redirectUrl', appConfig.isaRedirectUri);
  url.searchParams.set('isatimestamp', new Date().getTime());
  return url.toString();
};

function getRedirectUrl(countryCode, language) {
  if (countryCode === COUNTRY_CODE_BE) {
    if (language.toLowerCase() === 'fr') {
      return appConfig.wdPLPRedirectUrlFR;
    } else {
      return appConfig.wdPLPRedirectUrlBE;
    }
  }

  return appConfig.wdPLPRedirectUrlNL;
}
