import { Link } from 'react-router-dom';

const Logo = () => {
  return (
    <div>
      <Link to="/">
        <img src="/assets/logo.svg" alt="HEMA" />
      </Link>
    </div>
  );
};
export default Logo;
