import React from 'react';
import { useTranslation } from 'react-i18next';

const OrderNotFound = ({ orderId }) => {
  const { t } = useTranslation();

  return (
    <div className="info">
      <h1 className="mb30">{t('wdOrderOverview.notFound', { orderId })}</h1>
    </div>
  );
};
export default OrderNotFound;
