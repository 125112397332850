import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from  '../../styles/components/ConfigurationDetails.module.scss';
import { convertCentsToDollars } from '../../utils/componentUtils';
import FormattedPrice from '../common/FormattedPrice';

const ConfigurationDetails = ({ polygon }) => {
  const { t } = useTranslation();

  const widthHeightContent = polygon && polygon.configuration ? (
    <div className={styles.content}>
      <span>{`${t('wdOrderOverview.lineItem.width')} ${polygon.configuration.width}`}</span>
      <span>{`${t('wdOrderOverview.lineItem.height')} ${polygon.configuration.height}`}</span>
    </div>
  ) : null;

  const propertiesContent =
  polygon && polygon.configuration && polygon.configuration.properties
    ? polygon.configuration.properties.map((property, index) => {
      return (
        <div className={styles.content} key={index}>
          <span>{`${property.displayLabel}: ${property.displayValue}`}</span>
        </div>
      );
    })
    : null;

  function getPriceContent() {
    if (!polygon) {
      return null;
    }

    let manufactureCost = 0;
    let manufactureCostHTML = null;
    if (polygon.manufactureCost?.prices?.totalPrice) {
      manufactureCost = polygon.manufactureCost.prices.totalPrice;
      manufactureCostHTML = <><span>{`${t('global.manufactureCost')}:`}</span> <span><FormattedPrice price={convertCentsToDollars(manufactureCost)}/></span></>;
    }

    return (
      <div className={styles.content}>
        <p className="bolder">{t('receipt.order.overview.price.column.name')}</p>
        <span>{`${t('global.curtainFabric')}:`}</span> <span><FormattedPrice price={convertCentsToDollars(polygon.prices.totalPrice)}/></span>
        {manufactureCostHTML}
      </div>
    );
  }

  const configurationContent = polygon && polygon.configuration ? (
    <div className={styles.overviewDetail}>
      <div className={styles.top}>
        <p className="bolder">{t('wdOrderOverview.lineItem.dimensions')}</p>
        {widthHeightContent}
      </div>
      <div className={styles.bot}>
        <p className="bolder">{t('wdOrderOverview.lineItem.composition')}</p>
        {propertiesContent}
      </div>
      {getPriceContent()}
    </div>
  ) : null;

  return configurationContent;
};
export default ConfigurationDetails;
