import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ModalContext, { MODAL_DIALOG_TYPE } from '../../contexts/modal-context';
import OrderTotal from './OrderTotal';
import BackLink from '../common/BackLink';
import Spinner from '../common/Spinner';
import { setSSORedirectDataAndLogout } from '../../utils/authUtils';
import { KEY_CODE_ENTER } from '../../utils/constants';
import NotFoundState from '../states/NotFoundState';
import EmptySearch from '../states/EmptySearch';
import ServerError from '../states/ServerError';
import OrderService from '../../services/OrderService';
import OrderListItem from '../OrderListItem';
import Accordion from '../common/Accordion';
import { isOrderId } from '../../utils/OrderUtils';
import ShipmentService from '../../services/ShipmentService';
import styles from '../../styles/components/OrderLookupContent.module.scss';
import Modal from '../common/Modal';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SvgPrintIcon } from '../../../src/assets/print.svg';
import { convertCentsToDollars, generateId } from '../../utils/componentUtils';

const OrderLookupContent = ({ orderId, updateOrderIdHashParam }) => {
  const { t } = useTranslation();
  const { openModal } = useContext(ModalContext);
  const location = useLocation();
  const [inputValue, setInputValue] = useState(orderId);
  const [orderData, setOrderData] = useState({});
  const [httpError, setHttpError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const searchInputRef = useRef();
  const [isEventBlocked, setEventBlocked] = useState(false);
  const [isAccordionOpened, setIsAccordionOpened] = useState(false);
  const [confirmationButtonActive, setConfirmationButtonActive] = useState(false);
  useEffect(() => {
    if (orderId !== inputValue) {
      setInputValue(orderId);
    }

    const getOrderData = async () => {
      setIsLoading(true);
      setHttpError(null);
      // orderLoaded({}, {});
      isOrderId(orderId)
        ? OrderService.getOrderDetails(orderId, orderLoaded, orderLoadedError, { orderId })
        : OrderService.getOrderDetailsByShipmentId(orderId, orderLoaded, orderLoadedError, { shipmentId: orderId });
    };

    if (orderId) {
      getOrderData();
    } else {
      orderLoaded({}, {});
    }

    function orderLoaded(response, onTheFlyData) {
      onTheFlyData.orderId = response.id;
      if (onTheFlyData.orderId && response.id !== onTheFlyData.orderId) {
        return;
      }

      if (onTheFlyData.shipmentId && !isSearchedByShipmentId(response.shipments, onTheFlyData.shipmentId)) {
        return;
      }

      setOrderData(response);
      setHttpError(null);
      setIsLoading(false);
    }

    function orderLoadedError(error) {
      setIsLoading(false);
      if (error.status === 401) {
        return setSSORedirectDataAndLogout(location.pathname, location.search, location.hash);
      }
      setHttpError(error);
    }

    function isSearchedByShipmentId(shipments, shipmentId) {
      return shipments.some((shipment) => shipment.shipmentId === shipmentId);
    }

    window.addEventListener('keydown', onKeyDownHandler, true);
    return () => {
      window.removeEventListener('keydown', onKeyDownHandler, true);
    };
  }, [orderId]);

  function onKeyDownHandler(event) {
    if (isEventBlocked || event.ctrlKey) {
      return;
    }

    if (event.target.tagName !== 'INPUT' && searchInputRef !== document.activeElement) {
      searchInputRef.current.focus();
    }
  }

  const searchHandler = (event) => {
    if (isEventBlocked) {
      return;
    }

    if (event.keyCode === KEY_CODE_ENTER) {
      searchOrder();
    }
  };

  function searchOrder() {
    updateOrderIdHashParam(inputValue.trim());
  }

  function onChangeHadler(event) {
    if (isEventBlocked) {
      return;
    }

    setInputValue(event.target.value.trim());
  }

  function orderLineCheckChanged(isChecked, callbackData) {
    setIsAccordionOpened(true);
    const _orderData = { ...orderData };
    _orderData.shipments[callbackData.shipmentIndex].lines[callbackData.orderIndex].selectedAsDelivered = isChecked;
    const allSelected = !_orderData.shipments[callbackData.shipmentIndex].lines.some((line) => !line.confirmed && !line.selectedAsDelivered);
    _orderData.shipments[callbackData.shipmentIndex].selectedAsDelivered = allSelected;

    if (isChecked) {
      setConfirmationButtonActive(isChecked);
    } else {
      const isAtLeastOneChecked = _orderData.shipments[callbackData.shipmentIndex].lines.some((line) => line.selectedAsDelivered);
      setConfirmationButtonActive(isAtLeastOneChecked);
    }
    setOrderData(_orderData);
  }

  function shipmentDeliveryCheckChanged(isChecked, callbackData) {
    setIsAccordionOpened(true);
    const _orderData = { ...orderData };
    _orderData.shipments[callbackData.shipmentIndex].selectedAsDelivered = isChecked;
    _orderData.shipments[callbackData.shipmentIndex].lines.filter((line) => !line.confirmed).forEach((line) => (line.selectedAsDelivered = isChecked));

    if (isChecked) {
      setConfirmationButtonActive(isChecked);
    } else {
      const isAtLeastOneChecked = _orderData.shipments.some((shipment) => shipment.selectedAsDelivered);
      setConfirmationButtonActive(isAtLeastOneChecked);
    }
    setOrderData(_orderData);
  }

  function openConfirmShipmentsDialog() {
    // TODO: Translation
    openModal({ message: t('dialog.shipment.confirm'), dialogType: MODAL_DIALOG_TYPE.CONFIRM_DECLINE });
  }

  function openPendingOrdersDialog() {
    // TODO: Translation
    openModal({ message: t('dialog.shipment.pending'), dialogType: MODAL_DIALOG_TYPE.OK });
  }

  function saveDeliveredOrderLines() {
    const dataToSave = [];
    orderData.shipments.forEach((shipment) => {
      dataToSave.push({
        shipmentId: shipment.shipmentId,
        orderLineIds: shipment.lines.filter((line) => line.selectedAsDelivered).map((line) => line.id),
      });
    });

    dataToSave.forEach((data) => {
      ShipmentService.markOrderLinesAsDelivered(data, null, linesUpdatesError, dataToSave);
    });

    const _orderData = { ...orderData };
    _orderData.shipments.forEach((shipment) => {
      let shipmentNotCompleted = false;
      shipment.lines.forEach((line) => {
        if (line.selectedAsDelivered || line.confirmed) {
          line.confirmed = true;
          delete line.selectedAsDelivered;
        } else {
          shipmentNotCompleted = true;
        }
      });

      if (!shipmentNotCompleted) {
        shipment.status = 'DeliveredToStore';
        setConfirmationButtonActive(false);
      }
    });

    setOrderData(_orderData);
  }

  function linesUpdatesError(error, onTheFlyData) {
    console.log('Error while saving lines', onTheFlyData);
  }

  function onOpenModalHandler() {
    setEventBlocked(true);
  }

  function onDeclineHandler() {
    setEventBlocked(false);
  }

  function onConfirmHandler() {
    setEventBlocked(false);
    saveDeliveredOrderLines();
  }

  function onOutterClickHandler() {
    setEventBlocked(false);
  }

  function onNeutralHandler() {
    //
  }

  return (
    <div>
      <BackLink />
      <div className={styles.searchContainer}>
        <div className={styles.searchWrapper}>
          <h1 className="mb30">{t('searchOrder.title')}</h1>
          <input
            type="text"
            className={styles.input}
            placeholder={`${t('global.orderNumber')}...`}
            value={inputValue}
            onChange={onChangeHadler}
            onKeyUp={searchHandler}
            autoFocus={true}
            ref={searchInputRef}
          />
          <img src="./assets/search.png" alt="search" className={styles.searchIcon} draggable="false" />
        </div>
      </div>

      <LookupContent
        order={orderData}
        isLoading={isLoading}
        httpError={httpError}
        isAccordionOpened={isAccordionOpened}
        confirmationButtonActive={confirmationButtonActive}
        callbacks={{ orderLineCheckChanged, shipmentDeliveryCheckChanged, openConfirmShipmentsDialog, openPendingOrdersDialog }}
      />
      <Modal onOpen={onOpenModalHandler} onOutterClick={onOutterClickHandler} onDecline={onDeclineHandler} onConfirm={onConfirmHandler} />
    </div>
  );
};

const LookupContent = ({ order, isLoading, httpError, callbacks, isAccordionOpened, confirmationButtonActive }) => {
  if (isLoading) {
    return wrapStateContent(<Spinner />);
  }

  if (httpError?.status === 404) {
    return (
      <div className={styles.searchContent}>
        <div className="w100 flexRow justifyCenter">
          <div className="w60">
            <NotFoundState />
          </div>
        </div>
      </div>
    );
  }

  function wrapStateContent(content) {
    return (
      <div className={styles.searchContent}>
        <div className="w100 flexRow justifyCenter">
          <div className="w100">{content}</div>
        </div>
      </div>
    );
  }

  if (!httpError && !Object.keys(order).length) {
    return (
      <div className="w100 flexRow justifyCenter">
        <div className="w30">
          <EmptySearch />
        </div>
      </div>
    );
  }

  if (httpError) {
    return wrapStateContent(<ServerError />);
  }

  const isDataLoaded = Object.keys(order).length;
  return isDataLoaded ? (
    <OrderDetails order={order} callbacks={callbacks} isAccordionOpened={isAccordionOpened} confirmationButtonActive={confirmationButtonActive} />
  ) : null;
};

const OrderDetails = ({ order, callbacks, isAccordionOpened, confirmationButtonActive }) => {
  const { t } = useTranslation();
  const { openModal } = useContext(ModalContext);

  function openDialog() {
    openModal({ message: t('dialog.order.cancel') });
  }

  const confirmButtonCallback = confirmationButtonActive ? callbacks.openConfirmShipmentsDialog : function () {};
  const hideConfirmButtonCss = confirmationButtonActive ? styles.active : '';
  return (
    <div className={styles.searchContent}>
      <OrderInfoHeader orderId={order.id} />
      <OrderStatus status={order.state} />
      <CustomerDetails customer={order.customer} />
      <div className={styles.trace}>
        <p>{`${t('global.trackAndTrace')} (${order.shipments.length})`}</p>
      </div>
      <ShipmentAccordions shipments={order.shipments} callbacks={callbacks} isAccordionOpened={isAccordionOpened} />
      <OrderTotal
        prices={{
          discountAmount: convertCentsToDollars(order.prices.discountAmount),
          totalPrice: convertCentsToDollars(order.prices.totalPrice),
          totalPriceBeforePromotions: convertCentsToDollars(order.prices.totalPriceBeforePromotions),
        }}
      />
      <div className={styles.bottomSection}>
        <button className={`${styles.positiveButton} ${hideConfirmButtonCss}`} onClick={confirmButtonCallback}>
          {t('global.confirm')}
        </button>
        <button className={`${styles.negativeButton} hide`} onClick={openDialog}>
          {t('searchOrder.cancel.button.text')}
        </button>
      </div>
    </div>
  );
};

const OrderStatus = ({ status }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.row}>
      <p className="bold">{t('seachOrder.status')}</p>
      <p className={styles.status}>{getStatus()}</p>
    </div>
  );

  function getStatus() {
    switch (status) {
      case 'CREATED':
        return t('orderStatus.created');
      case 'PLACED':
        return t('orderStatus.placed');
      case 'DOWNPAID':
        return t('orderStatus.downpaid');
      case 'CONFIRMED':
        return t('orderStatus.confirmed');
      case 'FULFILLED':
        return t('orderStatus.fulfilled');
      case 'PAID':
        return t('orderStatus.paid');
      case 'PENDING':
        return t('orderStatus.pending');
      case 'IN_PROGRESS':
        return t('orderStatus.inProgress');
      case 'IN_TRANSIT':
        return t('orderStatus.inTransit');
      case 'READY_FOR_PICKUP':
        return t('orderStatus.readyForPickup');
      case 'COMPLETED':
        return t('orderStatus.completed');
      case 'CANCELLED':
        return t('orderStatus.cancelled');
      default:
        return t('orderStatus.placed');
    }
  }
};

const ShipmentAccordions = ({ shipments, callbacks, isAccordionOpened }) => {
  function shipmentsAccordionItems() {
    return shipments.map((shipment, shipmentIndex) => {
      const shipmentExists = shipment.status !== 'UNSHIPPED';
      const items = shipment.lines.map((item, orderIndex) => {
        return (
          <OrderListItem
            key={`order_list_item_${item.id}`}
            polygon={item}
            showCheckbox={shipmentExists}
            isChecked={(item.selectedAsDelivered || item.confirmed) && shipmentExists}
            disableCheckbox={item.confirmed}
            lineOrderCheckChanged={callbacks.orderLineCheckChanged}
            callbackData={{ shipmentIndex, orderIndex }}
          />
        );
      });

      const isButtonDisabled = shipment.status !== 'Shipped' || shipment.status === 'UNSHIPPED';
      const checkboxProps = {
        checked: shipment.selectedAsDelivered || isButtonDisabled,
        disabled: isButtonDisabled,
        onChangeHandler: callbacks.shipmentDeliveryCheckChanged,
        callbackData: { shipmentIndex },
      };

      const accordionTitle = shipment.status === 'UNSHIPPED' ? 'Not Shipped' : `${shipment.shipmentId} (${shipment.lines.length})`;
      return (
        <Accordion
          title={accordionTitle}
          itemsComponent={items}
          checkboxProps={checkboxProps}
          isOpen={isAccordionOpened}
          key={generateId('shipment_accordions')}
        />
      );
    });
  }

  return <>{shipmentsAccordionItems()}</>;
};

const OrderInfoHeader = ({ orderId }) => {
  const { t } = useTranslation();
  const orderConfirmationLink = `/order-confirmation/${orderId}`;
  return (
    <h3>
      <span className={styles.print}>
        <Link to={orderConfirmationLink} className={`link ${styles.link}`}>
          {t('searchOrder.subtitle.order')} {orderId}
          <span className={styles.icon}>
            <SvgPrintIcon />
          </span>
        </Link>
      </span>
    </h3>
  );
};

const CustomerDetails = ({ customer }) => {
  const { t } = useTranslation();
  const customerDetailsContent = customer ? (
    <>
      <div className={styles.row}>
        <p className="bold">{t('searchOrder.customer.name')}</p>
        <p>{customer.name}</p>
      </div>
      <div className={styles.row}>
        <p className="bold">{t('searchOrder.customer.email')}</p>
        <p>{customer.email}</p>
      </div>
      <div className={styles.row}>
        <p className="bold">{t('searchOrder.customer.phone')}</p>
        <p>{customer.phone}</p>
      </div>
    </>
  ) : null;

  return customerDetailsContent;
};

export default OrderLookupContent;
