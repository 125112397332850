export function generateId(prefix = '') {
  return prefix + Math.random().toString(20).substr(2, 12);
}

export function getPercentage(value, percentage) {
  if (typeof value === 'string') {
    value = parseFloat(value);
  }
  return value * (percentage / 100);
}

export function formatPrice(value) {
  const [formattedPriceMain, formattedPriceDecimal] = value.toString().split('.');
  if (formattedPriceDecimal === '00' || formattedPriceDecimal === undefined) {
    return `${formattedPriceMain}.00`;
  }

  if (formattedPriceDecimal.length === 1) {
    return `${formattedPriceMain}.${formattedPriceDecimal}0`;
  }
  return `${formattedPriceMain}.${formattedPriceDecimal.substring(0, 2)}`;
}

export function convertCentsToDollars(cents) {
  if (!cents) {
    return 0;
  }

  const centsString = cents.toString();
  if (centsString.length === 1) {
    return `0.0${centsString}`;
  }

  if (centsString.length === 2) {
    return `0.${centsString}`;
  }

  return `${centsString.substring(0, centsString.length - 2)}.${centsString.substring(centsString.length - 2)}`;
}

export function roundMoney(rawAmount) {
  if (typeof rawAmount !== 'number') {
    throw new Error(`amount does not have type number: ${typeof rawAmount}`);
  }
  if (Number.isNaN(rawAmount)) {
    throw new Error('amount is not a number');
  }

  // the addition of EPSILON is needed to correctly round numbers like 1.005 to 1.01. This is because 1.005 * 100 is
  // 100.49999999999999 (due to rounding error) and not the expected 100.5. Adding EPSILON fixes this:
  // (1.005+Number.EPSILON) * 100 = 100.50000000000001
  return Math.round((rawAmount + Number.EPSILON) * 100) / 100;
}
