import { useContext } from 'react';
import ModalContext, { MODAL_DIALOG_TYPE } from '../../contexts/modal-context';
import '../../styles/components/modal.scss';

const Modal = ({ onOpen, onOutterClick, onConfirm, onDecline, onNeutral }) => {
  const {isOpen, closeModal, getOptions } = useContext(ModalContext);

  function onDeclineHandler() {
    closeModal();
    onDecline?.();
  }

  function onConfirmHandler() {
    closeModal();
    onConfirm?.(getOptions().actionId);
  }

  function onNeutralHandler() {
    closeModal();
    onNeutral?.(getOptions().actionId);
  }

  function onOutterClickHandler() {
    onOutterClick?.();
  }

  function onOpenHandler() {
    onOpen?.();
  }

  function getDialogMessage() {
    return getOptions().message;
  }

  function getActionButtonsContent() {
    const options = getOptions();
    if (options.dialogType === MODAL_DIALOG_TYPE.OK) {
      return (
        <button className="decline" onClick={onNeutralHandler}>{options.buttonLabels.ok || 'OK'}</button>
      );
    }

    return (
      <>
        <button className="confirm" onClick={onConfirmHandler}>{options.buttonLabels.confirm || 'Confirm'}</button>
        <button className="decline" onClick={onDeclineHandler}>{options.buttonLabels.decline || 'Decline'}</button>
      </>
    );
  }

  function getModalContent() {
    onOpenHandler();

    return (
      <div className="modal">
        <div className="overlay" onClick={onOutterClickHandler}></div>
        <div className="modal-content">
          <h4>{getDialogMessage()}</h4>
          <div className="btns">
            {getActionButtonsContent()}
          </div>
        </div>
      </div>
    );
  }

  return isOpen() ?
    getModalContent() :
    null;
};
export default Modal;
