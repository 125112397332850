import React, { useState } from 'react';

const ACTIVE_MODAL_CLASS = 'active-modal';
export const MODAL_DIALOG_TYPE = {
  OK: 'OK',
  CONFIRM_DECLINE: 'CONFIRM_DECLINE'
};

const defaultState = {
  isOpen: false,
  message: '',
  dialogType: MODAL_DIALOG_TYPE.CONFIRM_DECLINE,
  actionId: '',
  buttonLabels: {},
};

const ModalContext = React.createContext(defaultState);

export const ModalContextProvider = (props) => {
  const [options, setOptions] = useState(defaultState);

  const isOpen = () => {
    return options.isOpen;
  };

  const openModal = (customOptions = {isOpen: true}) => {
    if (!isOpen()) {
      document.body.classList.add(ACTIVE_MODAL_CLASS);
    }

    customOptions.isOpen = true;
    setOptions(options => ({...options, ...customOptions}));
  };

  const closeModal = (customOptions = {isOpen: false}) => {
    if (isOpen()) {
      document.body.classList.remove(ACTIVE_MODAL_CLASS);
    }

    customOptions.isOpen = false;
    setOptions(options => ({...options, ...customOptions}));
  };
  
  const getOptions = () => {
    return options;
  };

  return <ModalContext.Provider value={{ isOpen, openModal, closeModal, getOptions }}>
    {props.children}
  </ModalContext.Provider>;
};

export default ModalContext;