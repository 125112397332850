import styles from '../styles/components/OrderItem.module.scss';
import { convertCentsToDollars } from '../utils/componentUtils';
import FormattedPrice from '../components/common/FormattedPrice';
import { ORDER_COOLDOWN_IN_DAYS, ORDER_ID_SEARCH_PARAM } from '../utils/constants';
import { isCustomerInfoEditabe, isDateInBetweenDays } from '../utils/OrderUtils';

function OrderItem({order}) {
  let canEdit = false;
  setEditFlag();

  function openOrder() {
    if (order.status === 'CREATED' || order.status === 'PLACED' || order.status === 'CONFIRMED') {
      window.open(`/order-confirmation/${order.id}`, '_blank');
      return;
    }
    window.open(`/search#${ORDER_ID_SEARCH_PARAM}=${order.id}`, '_blank');
  }

  function setEditFlag() {
    canEdit = isCustomerInfoEditabe(order.status) || isDateInBetweenDays(new Date(), order.fullDate, ORDER_COOLDOWN_IN_DAYS);
  }

  function editOrder() {
    window.open(`/overview/${order.id}`, '_self');
    return;
  }

  function getOrderDate(orderDate) {
    if (!orderDate) {
      return '';
    }

    const items = orderDate.split('-');
    if (items.length != 3) {
      return '';
    }

    return `${items[2]}-${items[1]}-${items[0]}`;
  }

  return (
    <div className={`${styles.container} ${styles.pointer}`}>
      <div className={`${styles.externalLink} ${canEdit ? styles.edit : styles.search}`} onClick={canEdit ? editOrder : openOrder}></div>
      <div className={styles.itemWrapper} onClick={openOrder}>
        <div className={`${styles.item} ${styles.orderIdCell}`}>
          <span>{order.id}</span>
        </div>
        <div className={`${styles.item} ${styles.customerCell}`}>
          <span>{order.customer}</span>
        </div>
        <div className={`${styles.item} ${styles.dateCell}`}>
          <span>{getOrderDate(order.date)}</span>
        </div>
        <div className={`${styles.item} ${styles.priceCell} ${styles.alignRight}`}>
          <FormattedPrice price={convertCentsToDollars(order.price)} />
        </div>
      </div>
    </div>
  );
}
export default OrderItem;
