import { useEffect, useState } from 'react';
import { generateId } from '../utils/componentUtils';
import AutoSuggestionItem from './AutoSuggestionItem';

const AutoSuggestion = ({items, value, callback}) => {
  const [autoSuggestionId] = useState(generateId('auto_suggestion_id_'));
  const [isVisible, setIsVisible] = useState(true);
  const [matchValue, setMatchValue] = useState(null);
  
  useEffect(() => {
    setIsVisible(matchValue === null || matchValue !== value);
    setMatchValue(value);

    document.addEventListener('click', onGlobalClickHandler, true);
    return () => {
      document.removeEventListener('click', onGlobalClickHandler, true);
    };

    function onGlobalClickHandler(event) {
      const element = event.target.closest(`.auto--suggestion--element[autosuggestionid=${autoSuggestionId}]`);
      if (element) {
        return callback(items[Number(element.getAttribute('itemindex'))]);
      }
  
      setIsVisible(false);
    }
  }, [value]);

  function getItems() {
    if (!isVisible) {
      return null;
    }

    const cssStyle = {
      position: 'relative',
      display: 'block',
      flexDirection: 'column',
      background: 'white',
      width: '100%',
      maxHeight: '300px',
      overflowY: 'auto',
      zIndex: '1000',
    };

    let index = 0;
    const itemsComponents =  items
      .map((item) => {
        let wrappedItem = {};
        wrappedItem.value = item;
        wrappedItem.index = index++;
        wrappedItem.position = String(item).toLowerCase().indexOf(String(value).toLowerCase());
        wrappedItem.preHighlightedText = item.substring(0, wrappedItem.position);
        wrappedItem.highlightedText = item.substring(wrappedItem.position, wrappedItem.position + value.length);
        wrappedItem.postHighlightedText = item.substring(wrappedItem.position + value.length);
        
        return wrappedItem;
      })
      .filter((item) => {
        return item.position >= 0;
      })
      .map((item) => {
        return (
          <div className='auto--suggestion--element'
            itemindex={item.index}  
            autosuggestionid={autoSuggestionId}>
            <AutoSuggestionItem text={item.value} item={item} key={item} />
          </div>
        );
      });
    
    return itemsComponents.length > 0
      ? <div style={cssStyle}>{itemsComponents}</div>
      : null;
  }

  return <div>{getItems()}</div>;
};
export default AutoSuggestion;
