import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const BackLink = ({ route = '/' }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Link to={route} className="back">
        ← {t('global.back')}
      </Link>
    </div>
  );
};

export default BackLink;