import styles from '../../styles/components/states/EmptySearch.module.scss';

const EmptySearch = () => {
  return <div className={styles.emptyState}>
    <div className={styles.container}>
      <img className={styles.icon} src="/assets/search.svg" alt=""  draggable="false" />
    </div>
  </div>;
};
export default EmptySearch;
