import { ORDER_ID_LENGTH, ORDER_ID_PREFIX } from './constants';

export const OrderStatus = {
  CREATED: 'CREATED',
  PLACED: 'PLACED',
  DOWNPAID: 'DOWNPAID',
  CONFIRMED: 'CONFIRMED',
  FULFILLED: 'FULFILLED',
  PAID: 'PAID',
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  IN_TRANSIT: 'IN_TRANSIT',
  READY_FOR_PICKUP: 'READY_FOR_PICKUP',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
};

export function isOrderId(value) {
  return value && String(value).startsWith(ORDER_ID_PREFIX) && value.length === ORDER_ID_LENGTH;
}

export function isDateInBetweenDays(date, fromDate, days) {
  const createdAt = new Date(fromDate);
  const canEditUntil = new Date(fromDate);
  canEditUntil.setDate(canEditUntil.getDate() + days);
  return createdAt < date && date < canEditUntil;
}

export function isCustomerInfoEditabe(orderStatus) {
  const statuses = [
    OrderStatus.CREATED,
    OrderStatus.PLACED,
    OrderStatus.DOWNPAID,
    OrderStatus.CONFIRMED,
    OrderStatus.FULFILLED,
    OrderStatus.PAID,
    OrderStatus.PENDING,
    OrderStatus.IN_PROGRESS,
    OrderStatus.IN_TRANSIT,
    OrderStatus.READY_FOR_PICKUP,
    OrderStatus.COMPLETED,
    OrderStatus.CANCELLED,
  ];

  if (orderStatus === OrderStatus.CANCELLED) {
    return false;
  }

  return statuses.indexOf(orderStatus) < statuses.indexOf(OrderStatus.IN_PROGRESS);
}