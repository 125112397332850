import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../styles/components/Dropdown.module.scss';
import { generateId } from '../utils/componentUtils';

const Dropdown = ({items, callback}) => {
  const { t } = useTranslation();
  const dropdownId = generateId('dropdown_');
  const defaultButtonText = `-- ${t('global.select')} --`;
  const [info, setInfo] = useState({
    isVisible: false,
    selectedIndex: -1,
  });

  useEffect(() => {
    document.addEventListener('click', onGlobalClickHandler, true);
    return () => {
      document.removeEventListener('click', onGlobalClickHandler, true);
    };
  });

  function onGlobalClickHandler(event) {
    const element = event.target.closest(`.dropdown--element[dropdownid=${dropdownId}]`);
    let index = -1;

    if (element) {
      index = Number(element.getAttribute('attrindex'));
      if (info.selectedIndex !== index) {
        const data = element.id === -1 ? null : element.id;
        callback(data, null);
      }
      
      setInfo({
        ...info,
        isVisible: false,
        selectedIndex: index,
      });
      return;
    }

    if (event.target.closest(`#${dropdownId}`)) {
      return toggleFilterDropdown();
    }
    
    setInfo({
      ...info,
      isVisible: false,
    });
  }

  function toggleFilterDropdown() {
    setInfo({
      ...info,
      isVisible: !info.isVisible,
    });
  }

  function getItems() {
    return (
      <div className={styles.itemsContainer} >
        {info.selectedIndex !== -1 ? getDefaultItem() : null}
        {
          items.map((item, index) => {
            if (index === info.selectedIndex) {
              return null;
            }
            return getItem(item.value, index++, item.text);
          })
        }
      </div>
    );
  }

  function getItem(value, id, text) {
    return (<div className={`${styles.item} ${'dropdown--element'}`} 
      id={value}
      key={id}
      dropdownid={dropdownId} attrindex={id}>
      <div className={styles.itemWrapper}>
        {text}
      </div>
    </div>);
  }

  function getLabel() {
    if (info.selectedIndex === -1) {
      return defaultButtonText;
    }
    return items[info.selectedIndex].text;
  }

  function getDefaultItem() {
    if (info.selectedIndex === -1) {
      return null;
    }

    return getItem(-1, -1, defaultButtonText);
  }

  return (
    <div id={dropdownId} className={styles.button}>
      <span className={styles.container}>
        {getLabel()}
      </span>
      { info.isVisible ? getItems() : null }
    </div>
  );
};
export default Dropdown;
