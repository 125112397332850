import React from 'react';
import Logo from '../common/Logo';
import Barcode from './Barcode';
import styles from '../../styles/components/ReceiptHeader.module.scss';

const ReceiptHeader = ({ orderId, deliveryId }) => {
  return (
    <div className={styles.navbar}>
      <div className={styles.container}>
        <Logo />
        <div className={styles.barcodes}>
          {deliveryId ? <Barcode id={'barcoe_delivery_id'} value={deliveryId} /> : null}
          <Barcode id={'barcode_order_id'} value={orderId} />
        </div>
      </div>
    </div>
  );
};
export default ReceiptHeader;
