import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../styles/pages/ThankYouPage.module.scss';

const ThankYouPage = () => {
  const { t } = useTranslation();
  
  function goToHomepage() {
    window.open('/', '_self');
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.message}>
          {t('thankYou')}
        </div>
        <div className={styles.backBottom}>
          <div className={styles.wrapper}>
            <button onClick={goToHomepage}>
              {`< ${t('global.back')}`}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ThankYouPage;
