const appConfig = {
  region: process.env.REACT_APP_REGION,
  userPool: process.env.REACT_APP_COGNITO_USERPOOL_ID,
  userPoolDomain: process.env.REACT_APP_COGNITO_USERPOOL_DOMAIN,
  clientId: process.env.REACT_APP_CLIENT_ID,
  callbackUri: process.env.REACT_APP_CALLBACK_URI,
  signoutUri: process.env.REACT_APP_SIGNOUT_URI,
  tokenScopes: process.env.REACT_APP_TOKEN_SCOPES.split(','),
  apiUri: '',
  isaRedirectUri: process.env.REACT_APP_ISA_REDIRECT_URL,
  wdPLPRedirectUrlNL: process.env.REACT_APP_WD_PLP_REDIRECT_URL_NL,
  wdPLPRedirectUrlBE: process.env.REACT_APP_WD_PLP_REDIRECT_URL_BE,
  wdPLPRedirectUrlFR: process.env.REACT_APP_WD_PLP_REDIRECT_URL_FR,
};

export default appConfig;
