import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../components/common/Modal';
import ModalContext, { MODAL_DIALOG_TYPE } from '../contexts/modal-context';
import { getStoreId, setSSORedirectDataAndLogout } from '../utils/authUtils';

const Home = () => {

  const styles = {
    margin: '0px 20px 20px 20px',
    paddingTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  };
  
  const { t } = useTranslation();
  const { openModal } = useContext(ModalContext);
  const MODAL_ACTION_TYPES = {
    LOG_OUT: 'LOG_OUT',
  };

  useEffect(() => {
    const storeId = getStoreId();
    if (!storeId) {
      openModal({
        message: t('errors.onlyStoreUsers'),
        dialogType: MODAL_DIALOG_TYPE.OK,
        actionId: MODAL_ACTION_TYPES.LOG_OUT,
        buttonLabels: {
          ok: t('user.action.logout'),
        },
      });
    }
  }, []);

  
  function onConfirmHandler(actionId) {
    if (actionId === MODAL_ACTION_TYPES.LOG_OUT) {
      return setSSORedirectDataAndLogout();
    }
  }

  return (
    <div style={styles}>
      <img className="w100 h100" src="./assets/bgd-home.jpg" alt="content" />
      <Modal onNeutral={onConfirmHandler} />
    </div>
  );
};

export default Home;
