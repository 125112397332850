import React, { useReducer } from 'react';
import sessionReducer from '../reducers/sessionReducer';
import { localeStorageObjectKeys } from '../utils/constants';

const defaultState = {
  credentials: {
    accessToken: '',
    idToken: '',
    refreshToken: '',
    expires: 0,
  },
  user: {
    cognitoUserName: '',
    email: '',
    name: '',
  }
};

const AuthContext = React.createContext({ state: defaultState });

export const AuthContextProvider = (props) => {
  const initState = () => {
    const serializedSession = localStorage.getItem(localeStorageObjectKeys.SESSION);
    if (serializedSession) {
      const session = JSON.parse(serializedSession);
      session.isLoggedIn = localStorage.getItem(localeStorageObjectKeys.LOGGED_IN) === 'true';
      return session;
    }
    return defaultState;
  };

  const [state, dispatch] = useReducer(sessionReducer, defaultState, initState);

  return <AuthContext.Provider value={{ state, dispatch }}>{props.children}</AuthContext.Provider>;
};

export default AuthContext;
