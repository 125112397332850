import React, { useEffect, useState } from 'react';
import Table from '../components/Table';
import styles from '../styles/components/OrderConfirmation.module.scss';
import ReceiptHeader from '../components/receipt/ReceiptHeader';
import ProductDetails from '../components/receipt/ProductDetails';
import Dots from '../components/receipt/Dots';
import Disclaimer from '../components/Disclaimer';
import { useTranslation } from 'react-i18next';
import { convertCentsToDollars, generateId, getPercentage } from '../utils/componentUtils';
import OrderService from '../services/OrderService';
import Spinner from '../components/common/Spinner';
import { getStoreId } from '../utils/authUtils';
import { useParams } from 'react-router';
import { CURTAIN_MANUFACTURE_COST_PREFIX } from '../utils/constants';
import FormattedPrice from '../components/common/FormattedPrice';

const OrderConfirmation = () =>  {
  const {t} = useTranslation();
  const [order, setOrder] = useState({});
  const { orderId } = useParams();

  useEffect(() => {
    if (!Object.keys(order).length) {
      OrderService.get(orderId, orderLoaded, orderLoadedError);
    }

    function orderLoaded(data) {
      const result = OrderService.getOrderWithExtendedPolygons(data);
      setOrder(order => ({...result, ...order}));
    }

    function orderLoadedError(error) {
      console.error('Error while loading order');
    }
  }, [order]);

  return (
    <>
      <div className={`${styles.container} printW100`}>
        {getPageContent()}
      </div>
    </>
  );

  function getPageContent() {
    return Object.keys(order).length ?
      <>
        <div className={`${styles.page} pageBreakAfter`}>
          {getPageOneContent()}
        </div>
        
        <hr className='hideOnPrint'/>

        <div className={styles.page}>
          {getPageTwoContent()}
        </div>
      </> :
      <Spinner />;
  }

  function getPageOneContent() {
    const tableHeaders = [
      t('receipt.order.overview.product.group.column.name'),
      t('receipt.order.overview.article.number.column.name'),
      t('receipt.order.overview.product.name.column.name'),
      t('receipt.order.overview.price.column.name'),
      t('receipt.order.overview.downpayment.column.name'),
    ];

    const priceHeaders = [
      t('receipt.order.overview.total.price.label'),
      t('receipt.order.overview.total.downpayment.label'),
      t('receipt.order.overview.total.outstanding.label'),
    ];

    let suggestedDownPaymentInDollars = 0;
    const itemsArrays = order.polygons
      .map((polygon) => {
        const manufactureCost = polygon.manufactureCost ? polygon.manufactureCost.prices.totalPrice : 0;
        const itemDownPayment = getPercentage(convertCentsToDollars(polygon.prices.totalPrice + manufactureCost), 25);
        suggestedDownPaymentInDollars += itemDownPayment;
        return [
          'gordijn',
          polygon.productId,
          polygon.title, 
          <FormattedPrice price={convertCentsToDollars(polygon.prices.totalPrice + manufactureCost)} />,
          <FormattedPrice price={itemDownPayment} />
        ];
      });

    const orderTotalPrice = convertCentsToDollars(order.prices.totalPrice);
    const priceArray = [[
      <FormattedPrice price={orderTotalPrice} />,
      <FormattedPrice price={suggestedDownPaymentInDollars} />,
      <FormattedPrice price={orderTotalPrice - suggestedDownPaymentInDollars} />
    ]];
    
    return (
      <>
        <div className={`${styles.flexStretch} flexColumn`}>
          <ReceiptHeader orderId={orderId} deliveryId={order.pickingOrderNumber} />
          <div>
          </div>
          <div>
            {getOrderInfoHtml()}
          </div>
          <Dots />
          <h3 className='textLeft'>{t('receipt.order.overview.title')}</h3>
          <div className={styles.table}>
            <Table header={tableHeaders} itemsArray={itemsArrays} isHorizontal={false} options={{growSize: 2}}/>
          </div>
          <div className={`${styles.flex} w100 alignRight`}>
            <div className={styles.priceContainer}>
              <Table header={priceHeaders} itemsArray={priceArray} isHorizontal={true} options={{border: false}}/>
            </div>
          </div>
        </div>
        <Disclaimer />
      </>
    );
  }

  function getPageTwoContent() {
    return (
      <>
        <div className={`${styles.flexStretch} flexColumn`}>
          <ReceiptHeader orderId={orderId} deliveryId={order.pickingOrderNumber} />
          <div>
            {getOrderInfoHtml()}
          </div>
          <Dots />

          <div>
            <h3 className='textLeft'>{t('receipt.order.details.title')}</h3>
            {getProductsDetails()}
          </div>
          <div className='noPageBreak'>
            <Dots />
          </div>
        </div>

       

        <div className={'w100 flexColumn noPageBreakBefore'}>
          <div className={`${styles.signatureContainer}`}>
            <div className={`${styles.signatureWrapper}`}>
              <h4 className='signature alignCenter'>{t('receipt.customer.signature.label')}</h4>
              <div className={styles.signatureLine}>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  function getOrderInfoHtml() {
    const customerHeader = [
      t('receipt.customer.name.label'),
      t('receipt.customer.phone.label'),
      t('receipt.customer.email.label')
    ];

    const customerInfo = [[
      order.customerInfo ? `${order.customerInfo.firstName} ${order.customerInfo.lastName}` : '',
      order.customerInfo?.phone || '',
      order.customerInfo?.email || ''
    ]];

    const hemaHeader = [
      t('receipt.hema.order.number.label'),
      t('receipt.employee.name.label')
    ];
    const hemaInfo = [[orderId, getStoreId()]];
    return (
      <div>
        <div className={styles.customerInfoTable}>
          <div>
            <Table key={generateId('table_customer_')}
              header={customerHeader} itemsArray={customerInfo} isHorizontal={true} 
              options={{border: false}} />
          </div>
          <div>
            <Table key={generateId('table_hema_')} 
              header={hemaHeader} itemsArray={hemaInfo} isHorizontal={true} options={{border: false}} />
          </div>
        </div>
      </div>
    );
  }

  function getProductsDetails() {
    const polygonsMap = [];
    order.polygons.forEach(polygon => {
      if (!polygonsMap[polygon.id]) {
        polygonsMap[polygon.id] = {};
      }

      if (polygon.productId.startsWith(CURTAIN_MANUFACTURE_COST_PREFIX)) {
        polygonsMap[polygon.id].manufactureCost = polygon;
      } else {
        polygonsMap[polygon.id].polygon = polygon;
      }

    });
    return Object.values(polygonsMap).map((polygon) => {
      return (<div className={'noPageBreakInside'}>
        <ProductDetails product={polygon} />
      </div>);
    });
  }
};

export default OrderConfirmation;
