import FormattedPrice from '../common/FormattedPrice';
import { useTranslation } from 'react-i18next';
import styles from '../../styles/components/OrderTotal.module.scss';

const OrderTotal = ({ prices }) => {
  const { t } = useTranslation();

  const orderTotalContent = prices ? (
    <div className={styles.content}>
      <div className={styles.text}>
        <div className={styles.priceContainer}>
          <div className={styles.priceItem}>
            <div>{t('searchOrder.prices.total.all')}</div>
            <FormattedPrice price={prices.totalPriceBeforePromotions} />
          </div>
          <div className={styles.priceItem}>
            <div>{t('searchOrder.prices.discount')}</div>
            <FormattedPrice price={prices.discountAmount} isRedColor={true} />
          </div>
          <div className={styles.priceItem}>
            <p className="bold">{t('global.total')}</p>
            <FormattedPrice price={prices.totalPrice} />
          </div>
        </div>
      </div>
    </div>
  ) : null;

  return orderTotalContent;
};
export default OrderTotal;
