import { useEffect, useState } from 'react';
import GlobalListItems from '../components/GlobalListItems';
import { generateId } from '../utils/componentUtils';
import styles from '../styles/components/PagingNavigation.module.scss';

const maxIndices = 9;
const pivotIndex = 6;
const halfSize = 4;

const PagingNavigation = ({ itemsComponents, pageSize }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
  }, [currentIndex]);

  function onPageClick(event) {
    const index = Number(event.target.id);
    if (currentIndex === index) {
      return;
    }

    setCurrentIndex(index);
  }

  const getNagivation = () => {
    const indices = Math.ceil(itemsComponents.length / pageSize);
    if (indices === 1) {
      return null;
    }

    const leftBoundaryIndex = currentIndex >= pivotIndex && maxIndices <= indices;
    const rightBoundaryIndex = currentIndex + halfSize < indices && maxIndices <= indices;
    const startIndex = leftBoundaryIndex ? currentIndex - halfSize : 0;
    let endIndex = indices;
    if (rightBoundaryIndex) {
      if (leftBoundaryIndex) {
        endIndex = Math.min(currentIndex + halfSize, indices);
      } else {
        endIndex = Math.min(maxIndices, indices);
      }
    }

    const navigation = [];
    for (let pageIndex = startIndex; pageIndex < endIndex; pageIndex++) {
      navigation.push(
        <div
          id={pageIndex}
          key={pageIndex}
          className={`${styles.pageIndex} ${currentIndex === pageIndex ? styles.active : ''}`}
          onClick={onPageClick}
        >
          {pageIndex + 1}
        </div>
      );
    }

    return (
      <div>
        {leftBoundaryIndex ? getLeftBounder() : null}
        {navigation.slice(0, maxIndices)}
        {rightBoundaryIndex ? getRightBounder() : null}
      </div>
    );
  };

  function getLeftBounder() {
    return (
      <div className={styles.dots}>
        <div
          id={0}
          key={0}
          className={`${styles.pageIndex} ${currentIndex === 0 ? styles.active : ''}`}
          onClick={onPageClick}>
        1
        </div>
        <span>...</span>
      </div>
    );
  }

  function getRightBounder() {
    const indices = Math.ceil(itemsComponents.length / pageSize);
    return (
      <div className={styles.dots}>
        <span>...</span>
        <div
          id={indices - 1}
          key={indices - 1}
          className={`${styles.pageIndex} ${currentIndex === indices - 1 ? styles.active : ''}`}
          onClick={onPageClick}
        >
          {indices}
        </div>
      </div>
    );
  }

  const index = currentIndex * pageSize;
  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <GlobalListItems
          id={generateId('globalListItem')}
          itemComponent={itemsComponents.slice(index, index + pageSize)}
        />
      </div>

      <div className={styles.navigation}>{getNagivation()}</div>
    </div>
  );
};
export default PagingNavigation;
