import { useTranslation } from 'react-i18next';
import styles from '../../styles/components/states/NotFoundState.module.scss';

const NotFoundState = () => {
  const { t } = useTranslation();
  return <div className={styles.emptyState}>
    <div className={styles.message}>
      <p>{t('global.mispouce')}</p>
    </div>
    <div className={styles.container}>
      <img className={styles.icon} src="/assets/emptyState.png" alt="" draggable="false" />
    </div>
  </div>;
};
export default NotFoundState;
