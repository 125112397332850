import React from 'react';
import styles from '../../styles/components/ProductDetails.module.scss';
import { useTranslation } from 'react-i18next';
import FormattedPrice from '../common/FormattedPrice';
import { convertCentsToDollars } from '../../utils/componentUtils';

const ProductDetails = ({ product }) => {
  const { t } = useTranslation();

  return (
    getContent()
  );

  function getContent() {
    return (
      <>
        <div className={`${styles.container} noPageBreakInside flexColumn`}>
          <div className="flexRow justifyBetween">
            <div className='flexRow w50'>
              <div className={styles.productInfo}>
                <div className={styles.thumbnail} style={{ backgroundImage: `url(${product.polygon.imageUrl})` }}></div>
              </div>

              <div className={styles.productInfo}>
                <div className={styles.header}>
                  <div className="bold mb10">{product.polygon.title}</div>
                  <div>{`${t('product.quantity')}: `}{product.polygon.quantity}</div>
                  <div>b: {product.polygon.configuration.width} x h: {product.polygon.configuration.height}</div>
                </div>
                <div>
                  <table>
                    {/* {getMaterialContent()} */}
                    {getEmptyTableRow()}
                    {getDimensionsContent()}
                    {getEmptyTableRow()}
                    {getPropertiesContent()}
                  </table>
                </div>
              </div>
            </div>

            <div className='flexRow justifyRight w50'>
              <div className={`${styles.productInfo} ${styles.priceContainer} flexColumn`}>
                {getPrices()}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  function getMaterialContent() {
    return (
      <>
        <tr>
          <td className='bold'>{t('product.materialAndColor')}</td>
        </tr>
        <tr>
          <td>{t('product.color')}:</td>
          <td>{product.polygon.title.replace(/gordijnstof/ig, '').trim()}</td>
        </tr>
      </>
    );
  }

  function getDimensionsContent() {
    return (
      <>
        <tr>
          <td className='bold'>
            {t('wdOrderOverview.lineItem.dimensions')}
          </td>
          <td></td>
        </tr>
        <tr>
          <td>
            {t('product.rails.width')}:
          </td>
          <td>
            {product.polygon.configuration.width}
          </td>
        </tr>
        <tr>
          <td>
            {t('product.rails.height')}:
          </td>
          <td>
            {product.polygon.configuration.height}
          </td>
        </tr>
      </>
    );
  }

  function getPropertiesContent() {
    const properties = product.polygon.configuration.properties;
    const products = properties.map((property) => {
      return <tr>
        <td>{property.displayLabel}:</td>
        <td>{property.displayValue}</td>
      </tr>;
    });
    return (
      <>
        <tr>
          <td className='bold'>
            {t('product.composition')}
          </td>
        </tr>
        {products}
      </>
    );
  }
  
  function getEmptyTableRow() {
    return (<tr><td className='mb10'></td></tr>);
  }

  function getTotalPriceContent(polygon) {
    const manufactureCost = polygon.manufactureCost ? polygon.manufactureCost.prices.totalPrice : 0;
    return <FormattedPrice price={convertCentsToDollars(polygon.prices.totalPrice + manufactureCost)} />;
  }
  
  function getDiscountHTML(discount) {
    if (!discount) {
      return null;
    }

    return (
      <>
        <td className='mr10'>
          {t('receipt.order.item.price.discount')}:
        </td>
        <td className='bold redText'>
          <FormattedPrice price={convertCentsToDollars(discount)} />
        </td>
      </>
    );
  }

  function getPrices() {
    let manufactureCostContent = null;
    let manufactureCostDiscountContent = null;
    if (product.polygon.manufactureCost) {
      manufactureCostContent = (
        <tr>
          <td className='mr10'>{t('receipt.order.item.price.manufactureCost.beforePromotion')}:</td>
          <td>
            <FormattedPrice price={convertCentsToDollars(product.polygon.manufactureCost.prices.totalPriceBeforePromotions)} />
          </td>
        </tr>
      );
      manufactureCostDiscountContent = (
        <tr>
          <td className='mr10'>{t('receipt.order.item.price.manufactureCost.discount')}:</td>
          <td className='bold redText'>
            <FormattedPrice price={convertCentsToDollars(product.polygon.manufactureCost.prices.discountAmount)} />
          </td>
        </tr>
      );
    }
    return (
      <table>
        <tr>
          <td>{t('receipt.order.item.price.beforePromotion')}:</td>
          <td>
            <FormattedPrice price={convertCentsToDollars(product.polygon.prices.totalPriceBeforePromotions)} />
          </td>
        </tr>
        {manufactureCostContent}
        <tr>
          {getDiscountHTML(product.polygon.prices.discountAmount)}
        </tr>
        {manufactureCostDiscountContent}
        <tr>
          <td></td>
          <td class="bold h3">
            {getTotalPriceContent(product.polygon)}
          </td>
        </tr>
      </table>
    );
  }

  function getProductInfoHtml() {
    const properties = product.polygon.configuration.properties;
    const products = properties.map((property) => {
      return <div className={`${styles.propertyRow} flexRow`}>
        <span>{`${property.displayLabel}: ${property.displayValue}`}</span>
      </div>;
    });

    if (products.length <= 4) {
      return <div className={`${styles.infoTable} ${styles.leftTable}`}>{products}</div>;
    }

    if (products.length <= 8) {
      return (
        <>
          <div className={`${styles.infoTable} ${styles.leftTable}`}>{products.slice(0, 4)}</div>
          <div className={`${styles.infoTable} ${styles.rightTable}`}>{products.slice(4, 8)}</div>
        </>
      );
    }

    return (
      <>
        <div className={`${styles.infoTable} ${styles.leftTable}`}>
          {products.slice(0, Math.ceil(products.length / 2))}
        </div>
        <div className={`${styles.infoTable} ${styles.rightTable}`}>
          {products.slice(Math.ceil(products.length / 2), products.length)}
        </div>
      </>
    );
  }
};
export default ProductDetails;
