import { useContext } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import AuthContext from '../contexts/auth-context';
import {
  getSSORedirectDataFromLocaleStorage,
  hasAuthorizationCodeInUrl,
  initSessionFromCallbackURI
} from '../utils/authUtils';

function SSOCallback() {
  const authCtx = useContext(AuthContext);
  const location = useLocation();
  const href = window.location.href;
  const ssoRedirectData = getSSORedirectDataFromLocaleStorage();
  initSession();

  function initSession() {
    if (hasAuthorizationCodeInUrl(location) && !authCtx.state.isLoggedIn) {
      initSessionFromCallbackURI(href);
    }
  }

  return <Redirect
    to={{
      pathname: ssoRedirectData?.path || '/',
      search: ssoRedirectData?.querystring || '',
      hash: ssoRedirectData?.hash || ''
    }}
  />;
}

export default SSOCallback;
