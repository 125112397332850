import React, { useState } from 'react';
import { setSSORedirectDataAndLogout } from '../../utils/authUtils';
import styles from '../../styles/components/UserSettings.module.scss';
import { generateId } from '../../utils/componentUtils';
import { useTranslation } from 'react-i18next';
import { getWindowDecorationPLPUrl } from '../../utils/windowDecorationUtils';
import { useHistory } from 'react-router';

const UserSettings = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [settingsId] = useState(generateId('user_settings_id_'));
  const [isOpen, setIsOpen] = useState(false);

  function openSettingsDropdown() {
    setIsOpen(true);
  }

  function closeSettingsDropdown() {
    setIsOpen(false);
  }

  function logout() {
    closeSettingsDropdown();
    setSSORedirectDataAndLogout();
  }

  const redirectToPLP = () => {
    closeSettingsDropdown();
    window.location.href = getWindowDecorationPLPUrl();
  };

  const redirectToRoute = (route) => {
    closeSettingsDropdown();
    if (route.toLowerCase() !== window.location.pathname.toLocaleLowerCase()) {
      history.push(route);
    }
  };

  function getSettingMenu() {
    if (isOpen) {
      return (
        <div className={styles.dropdown}>
          <div className={styles.dropdownWrapper}>
            {getNavigationContent()}
            <div className={styles.container}>
              <div className={`${styles.item} user--settings--item`} onClick={logout}>
                {t('global.logout')}
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }

  function getNavigationContent() {
    return (
      <>
        <div className={styles.container}>
          <div className={`${styles.item} user--settings--item`}
            onClick={() => redirectToPLP(getWindowDecorationPLPUrl())}>
            {t('configure')}
          </div>
        </div>
        <div className={styles.container}>
          <div className={`${styles.item} user--settings--item`}
            onClick={() => redirectToRoute('/search')}>
            {t('search')}
          </div>
        </div>
        <div className={styles.container}>
          <div className={`${styles.item} user--settings--item`}
            onClick={() => redirectToRoute('/store/orders')}>
            {t('store')}
          </div>
        </div>
      </>
    );
  }

  return (
    <div id={settingsId} onMouseEnter={openSettingsDropdown} onMouseLeave={closeSettingsDropdown}>
      <div className={styles.settings}>
        <img src="/assets/man_avatar.png" alt="avatar" draggable="false" />
      </div>
      {getSettingMenu()}
    </div>
  );
};
export default UserSettings;
