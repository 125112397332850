import React from 'react';
import styles from '../styles/components/Navbar.module.scss';
import Logo from './common/Logo';
import UserSettings from './common/UserSettings';

const Navbar = () => {
  return (
    <div className={styles.navbar}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <Logo />
        </div>
        <div>
          <UserSettings />
        </div>
      </div>
    </div>
  );
};
export default Navbar;
