const validationTypes = {
  // regex that validates a part of a name: a valid part consists of 1 or more international letters,
  // possibly with a space, single quote or dash in between.
  NAME_PART: /^(([\p{L}'][ \p{L}'-]*[\p{L}])|([\p{L}]))$/u,
  EMAIL: /\S+@\S+.\S+/,
  PHONE: /^[+-]?\d+(?:[.,]\d*)?$/,
};

export const validation = (value, type) => {
  if (validationTypes[type]) {
    return validationTypes[type].test(value);
  }

  return false;
};
