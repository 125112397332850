import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import { AuthContextProvider } from './contexts/auth-context';
import { ModalContextProvider } from './contexts/modal-context';
import './translations';

ReactDOM.render(
  <AuthContextProvider>
    <ModalContextProvider>
      <Router>
        <App />
      </Router>
    </ModalContextProvider>
  </AuthContextProvider>,
  document.getElementById('root')
);
