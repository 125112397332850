import { GET } from './ApiService';

const baseUrl = '/v1/stores';

export default class StoreService {
  static async getOrders(storeId, callback, callbackError, onTheFlyData) {
    GET({
      url: `${baseUrl}/${storeId}/orders`,
      token: true, 
    }, callback, callbackError, onTheFlyData
    );
  }

  static async getLanguage(storeId, callback, callbackError, onTheFlyData) {
    GET({
      url: `${baseUrl}/${storeId}/language`,
      token: true,
    }, callback, callbackError, onTheFlyData
    );
  }
}
