import { useEffect, useState } from 'react';
import Dropdown from '../components/Dropdown';
import PagingNavigation from '../components/PagingNavigation';
import OrderItem from './OrderItem';
import TextInput from '../components/TextInput';
import DateInput from '../components/DateInput';
import ServerError from '../components/states/ServerError';
import { generateId } from '../utils/componentUtils';
import styles from '../styles/components/StoreOrders.module.scss';
import Spinner from '../components/common/Spinner';
import { useTranslation } from 'react-i18next';
import NoDataState from '../components/states/NoDataState';
import { getSession } from '../utils/authUtils';
import StoreService from '../services/StoreService';

function StoreOrders() {
  const { t } = useTranslation();
  const filterTypes = {
    CUSTOMER_NAME: 'customer',
    ORDER_DATE: 'date',
    ORDER_NUMBER: 'id',
  };
  const dropdownItems = [
    {
      value: 'customer',
      text: t('global.customerName'),
    },
    {
      value: 'date',
      text: t('global.date'),
    },
    {
      value: 'id',
      text: t('global.orderNumber'),
    },
  ];
  // Never changed
  const [data, setData] = useState({orders: [], isLoaded: false});
  const [pagingInfo, setPagingInfo] = useState({
    filter: {
      filterBy: null,
      filterValue: null,
    },
    orders: [],
    pageSize: 30,
  });

  useEffect(() => {
    const getOrders = () => {
      if (!data.isLoaded) {
        const session = getSession();
        StoreService.getOrders(session.store.id, ordersLoaded, ordersLoadedError);
      }
    };

    const ordersLoaded = (data) => {
      setPagingInfo(pagingInfo => ({...pagingInfo, orders: data}));
      data = data || [];
      data.forEach((order) => {
        order.fullDate = order.date;
        order.date = order.date.split('T')[0];
      });
      setData({orders: data, isLoaded: true});
    };
  
    const ordersLoadedError = (error) => {
      setData({orders: [], isLoaded: true, serverError: true});
      console.error(error);
    };
  
    getOrders();
  }, [data]);

  return (
    <div className={styles.page}>
      <div className={styles.pageContainer}>
        {getPageContent()}
      </div>
    </div>
  );

  function getPageContent() {
    if (!data.isLoaded) {
      return getLoadingState();
    }

    if (data.serverError) {
      return <ServerError />;
    }

    if (!data.orders.length) {
      return getNoDataContent();
    }

    return (
      <>
        <div className={styles.container}>
          <div className={styles.filterContainer}>
            <div className={styles.filterGroup}>
              <span className={styles.group1}>{t('global.filterBy')}:</span>
              <div className={styles.fiterDropdown}>
                <Dropdown id={generateId('dd_filter_id')} items={dropdownItems} callback={changeFilter} />
              </div>
            </div>
            <div>{getFilterContent()}</div>
          </div>
        </div>
        <div className={`${styles.container}`}>
          {getContent()}
        </div>
      </>
    );
  }

  function getNoDataContent() {
    return (
      <div className="w100 flexRow justifyCenter">
        <div className="w30">
          <NoDataState />
        </div>
      </div>
    );
  }

  function getContent() {
    if (!pagingInfo.orders.length) {
      return getNoDataContent();
    }
    
    const orderComponents = pagingInfo.orders.map((order) => {
      return <OrderItem order={order} key={order.id} />;
    });

    return (
      <PagingNavigation 
        key={generateId('p_navigation_id')} 
        itemsComponents={orderComponents} pageSize={pagingInfo.pageSize} />
    );
  }

  function getLoadingState() {
    return <div className={`${styles.page} ${styles.contentMinHeight}`}>
      <Spinner />
    </div>;
  }

  function changeFilter(filterBy, filterValue) {
    const filterByValue = Object.values(filterTypes).find((filterType) => filterType === filterBy);
    const filterByProperty = filterByValue ? filterBy : null;

    const filteredOrders = [];
    data.orders.forEach((order) => {
      if (isMatchedOrder(order, filterByProperty, filterValue)) {
        filteredOrders.push(order);
      }
    });

    setPagingInfo({
      ...pagingInfo,
      filter: {
        ...pagingInfo.filter,
        filterBy: filterBy,
        filterValue: filterValue,
      },
      orders: filteredOrders,
      currentPage: 0,
      maxPage: Math.ceil(filteredOrders.length / pagingInfo.pageSize),
    });
  }
  
  function onFliterChanged(inputValue, data) {
    changeFilter(data.filterType, inputValue || null);
  }

  function getFilterContent() {
    switch (pagingInfo.filter.filterBy) {
      case filterTypes.CUSTOMER_NAME:
        return getFilterCustomerName();
      case filterTypes.ORDER_DATE:
        return getFilterOrderDate();
      case filterTypes.ORDER_NUMBER:
        return getFilterOrderNumber();
      default:
        return null;
    }
  }

  function getFilterCustomerName() {
    const autoSuggestionItems = [];

    data.orders.forEach((order) => {
      if (!autoSuggestionItems.includes(order.customer)) {
        autoSuggestionItems.push(order.customer);
      }
    });
    return <TextInput data={
      {
        buttonText: t('global.search'),
        callback: onFliterChanged,
        filterType: filterTypes.CUSTOMER_NAME,
        isWithButton: true,
        items: pagingInfo.orders,
        isAutoSuggestion: true,
        autoSuggestionItems: autoSuggestionItems,
      }
    } />;
  }

  function getFilterOrderDate() {
    return <DateInput data={
      {
        buttonText: t('global.search'),
        callback: onFliterChanged,
        filterType: filterTypes.ORDER_DATE,
        isWithButton: true,
      }
    } />;
  }

  function getFilterOrderNumber() {
    const autoSuggestionItems = [];

    data.orders.forEach((order) => {
      if (!autoSuggestionItems.includes(order.id)) {
        autoSuggestionItems.push(order.id);
      }
    });
    return <TextInput data={
      {
        buttonText: t('global.search'),
        callback: onFliterChanged,
        filterType: filterTypes.ORDER_NUMBER,
        isWithButton: true,
        items: pagingInfo.orders,
        isAutoSuggestion: true,
        autoSuggestionItems: autoSuggestionItems,
      }
    } />;
  
  }

  function isMatchedOrder(order, filterBy, filterValue) {
    if (filterBy === null || filterValue === null) {
      return true;
    }

    if (order[filterBy] === undefined || order[filterBy] === null) {
      return false;
    }

    const value = filterValue.toLowerCase();
    const orderValue = String(order[filterBy]).toLowerCase();

    const isAsteriskFirst = value[0] === '*';
    const isAsteriskLast = value[value.length - 1] === '*';
    if (isAsteriskFirst || isAsteriskLast) {
      if (isAsteriskFirst && isAsteriskLast) {
        return orderValue.includes(value.substring(1, value.length - 1));
      }
      if (isAsteriskFirst) {
        return orderValue.indexOf(value.substring(1)) !== -1;
      }
      return orderValue.indexOf(value.substring(0, value.length - 1)) === 0;

    }
    return orderValue === value;
  }
}
export default StoreOrders;
