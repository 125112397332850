import { useTranslation } from 'react-i18next';
import styles from '../../styles/components/states/NoDataState.module.scss';

const NoDataState = () => {
  const { t } = useTranslation();
  return <div className={styles.container}>
    <img className={styles.icon} src="/assets/search.svg" alt="" draggable="false" />
    <div className={styles.messageWrapper}>
      <div className={styles.message}>
        <p>{t('global.noData')}</p>
      </div>
    </div>
  </div>;
};
export default NoDataState;
