import { useTranslation } from 'react-i18next';
import styles from '../styles/components/Disclaimer.module.scss';

const Disclaimer = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.disclaimer}>
      <h4 className="textLeft">{t('global.disclaimer.title')}</h4>
      <p>{t('global.disclaimer.text')}</p>
    </div>);
};
export default Disclaimer;
