import React from 'react';
import styles from '../styles/pages/PageHolder.module.scss';
import '../styles/pages/styles.scss';

const PageHolder = ({ page, headerComponent = null, navComponent, showMenuOnMobile = false }) => {
  function getBodyContent() {
    return (
      <div className={`${styles.container} ${showMenuOnMobile ? styles.showNavMenu : ''}`}>
        {getNavigationContent()}
        <div className={styles.content}>
          <div className="w100 fitWidthMedium">{page}</div>
        </div>
      </div>
    );
  }

  function getNavigationContent() {
    if (navComponent) {
      return (
        <div className={`${styles.menu}`}>
          {navComponent}
        </div>
      );
    }
    return null;
  }

  return (
    <div className={styles.box}>
      {headerComponent}
      {getBodyContent()}
    </div>
  );
};

export default PageHolder;
