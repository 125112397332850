
export const parseUrlHashParams = () => {
  const hash = window.location.hash;
  if (!hash || !hash.substr(1)) {
    return {};
  }
  let hashObject = {};
  const params = (hash.substr(1)).split('&');
  params.forEach((param) => {
    const [key, value] = param.split('=');
    hashObject[key] = value;
  });
  return hashObject;
};

export const updateUrlHashParam = (key, value) => {
  const hashParams = parseUrlHashParams();
  hashParams[key] = value;
  window.location.hash = stringifyHashParams(hashParams);
};

const stringifyHashParams = (hashObject) => {
  const hashArray = [];
  Object.keys(hashObject)
    .filter(key => key && hashObject[key])
    .forEach((key) => hashArray.push(`${key}=${hashObject[key]}`));
  return `#${hashArray.join('&')}`;
};
