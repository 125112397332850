import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import OrderNotFound from '../components/order/OrderNotFound';
import { validation } from '../utils/validation';
import OrderService from '../services/OrderService';
import OrderListItem from '../components/OrderListItem';
import Spinner from '../components/common/Spinner';
import styles from '../styles/components/OrderOverview.module.scss';
import fromStyles from '../styles/components/Form.module.scss';
import ModalContext, { MODAL_DIALOG_TYPE } from '../contexts/modal-context';
import Modal from '../components/common/Modal';
import { getStoreLocale } from '../utils/authUtils';
import { convertCentsToDollars } from '../utils/componentUtils';
import { isDateInBetweenDays } from '../utils/OrderUtils';
import { ORDER_COOLDOWN_IN_DAYS } from '../utils/constants';
import OrderTotal from '../components/lookup/OrderTotal';

const OrderOverview = () => {
  const { openModal } = useContext(ModalContext);
  const [errors, setErrors] = useState({});
  const form = {
    male: useRef(),
    female: useRef(),
    unknown: useRef(),
    firstName: useRef(),
    middleName: useRef(),
    lastName: useRef(),
    companyName: useRef(),
    email: useRef(),
    phone: useRef(),
  };
  const [orderData, setOrderData] = useState({});
  const [error, setError] = useState(false);
  const { orderId } = useParams();
  const { t } = useTranslation();

  const MODAL_ACTION_TYPES = {
    CANCEL_ORDER: 'cancel_order',
    CONFIRM_ORDER: 'confirm_order',
  };

  useEffect(() => {
    if (!Object.keys(orderData).length) {
      OrderService.get(orderId, orderLoaded, orderLoadedError);
    }

    function orderLoaded(data) {
      setOrderData(OrderService.getOrderWithExtendedPolygons(data));
    }

    function orderLoadedError(error) {
      setError(true);
    }
  }, [orderData, errors]);

  const handleChange = (event) => {
    const target = event.target;
    const inputValue = target.value.trim();
    const validatorAttribute = target.attributes['validator'];
    const isRequiredAttribute = target.attributes['validate'];

    const _errors = { ...errors };
    if (isRequiredAttribute && validatorAttribute) {
      const isValid = validation(inputValue, validatorAttribute.value);
      _errors[target.name] = !isValid;
    }
    setErrors(_errors);
  };

  function isOrderCanBeEdited() {
    return isDateInBetweenDays(new Date(), orderData.createdAt, ORDER_COOLDOWN_IN_DAYS);
  }

  const addCustomerInfoHander = (event) => {
    if (!isOrderCanBeEdited()) {
      return;
    }

    const _errors = validateForm(true);
    setErrors(_errors);
    const hasError = Object.values(_errors).find((error) => error);
    if (!hasError) {
      openModal({
        message: t('dialog.customer.save.text'),
        dialogType: MODAL_DIALOG_TYPE.CONFIRM_DECLINE,
        actionId: MODAL_ACTION_TYPES.CONFIRM_ORDER,
        buttonLabels: {
          confirm: t('dialog.customer.save.confirm'),
          decline: t('dialog.customer.save.decline'),
        },
      });
      return;
    }
  };

  function validateForm(validateEmptyInputs = false) {
    const formKeys = Object.keys(form);
    const errors = {};
    formKeys.forEach((input) => {
      const inputValue = form[input].current.value.trim();
      const validatorAttribute = form[input].current.attributes['validator'];
      const isRequiredAttribute = form[input].current.attributes['validate'];
      if (isRequiredAttribute && validatorAttribute) {
        if (!validateEmptyInputs && !inputValue.length) {
          errors[input] = false;
        } else {
          const validators = validatorAttribute.value;
          const isValid = validation(inputValue, validators);
          errors[input] = !isValid;
        }
      }
    });
    return errors;
  }

  function onConfirmHandler(actionId) {
    if (actionId === MODAL_ACTION_TYPES.CANCEL_ORDER) {
      return goToHomepage();
    }

    if (actionId === MODAL_ACTION_TYPES.CONFIRM_ORDER) {
      return saveCustomerInfo();
    }
  }

  async function saveCustomerInfo() {
    const gender = Object.values(form).find((input) => {
      return input.current.attributes['gender'] && input.current.checked;
    });

    const customerInfo = {
      firstName: form.firstName.current.value,
      lastName: form.lastName.current.value,
      email: form.email.current.value,
      gender: gender.current.id,
      phone: form.phone.current.value,
      locale: await getStoreLocale(),
    };

    if (form.companyName.current.value) {
      customerInfo.companyName = form.companyName.current.value;
    }

    if (form.middleName.current.value) {
      customerInfo.secondName = form.middleName.current.value;
    }

    OrderService.addCustomerInfo(orderId, customerInfo, customerInfoSaved, customerInfoSaveError);
  }

  function goToHomepage() {
    window.location.replace('/');
  }

  function customerInfoSaved() {
    window.location.replace(`/order-confirmation/${orderId}`);
  }

  function customerInfoSaveError(error) {
    openModal({ message: t('dialog.customer.save.error'), dialogType: MODAL_DIALOG_TYPE.OK });
  }

  function cancelOrderHandler() {
    openModal({
      message: t('dialog.order.startOver.text'),
      dialogType: MODAL_DIALOG_TYPE.CONFIRM_DECLINE,
      actionId: MODAL_ACTION_TYPES.CANCEL_ORDER,
      buttonLabels: {
        confirm: t('global.yes'),
        decline: t('global.no'),
      },
    });
  }

  function getPageContent() {
    if (error) {
      return <OrderNotFound orderId={orderId} />;
    }

    if (!Object.keys(orderData).length) {
      return <Spinner />;
    }

    const orderListContent = orderData.polygons.map((polygon) => {
      return <OrderListItem polygon={polygon} key={polygon.id} />;
    });
    return (
      <>
        <div>
          <h1 className="mb30">{t('wdOrderOverview.title')}</h1>
          <p className="bold">
            {t('wdOrderOverview.subtitle')} {orderData.id}
          </p>
          {getFormContent()}
        </div>

        {orderListContent}

        <div className={styles.priceContainer}>
          <OrderTotal
            prices={{
              discountAmount: convertCentsToDollars(orderData.prices.discountAmount),
              totalPrice: convertCentsToDollars(orderData.prices.totalPrice),
              totalPriceBeforePromotions: convertCentsToDollars(orderData.prices.totalPriceBeforePromotions),
            }}
          />
        </div>

        <div className={`${styles.actionContainer} ${isOrderCanBeEdited() ? '' : 'hide'}`}>
          <button className={styles.primaryButton} onClick={addCustomerInfoHander}>
            {t('wdOrderOverview.button.continue')}
          </button>
          <button className={styles.secondaryButton} onClick={cancelOrderHandler}>
            {t('wdOrderOverview.button.startOver')}
          </button>
        </div>

        <Modal onConfirm={onConfirmHandler} />
      </>
    );
  }

  function getFormContent() {
    return (
      <form className={`${fromStyles.form} ${styles.orderOverviewResize}`}>
        <label>{t('form.gender.text')}</label>
        <div className={fromStyles.control}>
          <div className={fromStyles.rowRadioButtons}>
            <input
              type="radio"
              className={fromStyles.input}
              name="radios"
              gender="true"
              id="male"
              ref={form.male}
              defaultChecked={orderData.customerInfo?.gender === 'male'}
            />
            <label for="male">{t('form.gender.mr')}</label>
            <input
              type="radio"
              className={fromStyles.input}
              name="radios"
              gender="true"
              id="female"
              ref={form.female}
              defaultChecked={orderData.customerInfo?.gender === 'female'}
            />
            <label for="female">{t('form.gender.mrs')}</label>
            <input
              type="radio"
              className={fromStyles.input}
              name="radios"
              gender="true"
              id="unknown"
              ref={form.unknown}
              defaultChecked={orderData.customerInfo?.gender === 'unknown' || !orderData.customerInfo?.gender}
            />
            <label for="unknown">{t('form.gender.neutral')}</label>
          </div>
        </div>

        <div className={fromStyles.control}>
          <div className={`${fromStyles.textControl} w30 w33Resize`}>
            <label className={fromStyles.inputLabel}>{t('form.firstName')}</label>
            <input
              className={`${fromStyles.textInput} ${errors.firstName && fromStyles.errorInput}`}
              type="text"
              placeholder={t('form.firstName')}
              name="firstName"
              validator="NAME_PART"
              validate="true"
              ref={form.firstName}
              defaultValue={orderData.customerInfo?.firstName}
              onBlur={handleChange}
            />
            {errors.firstName && <p className={`${fromStyles.errorText} smallText`}>{t('form.error.firstName')}</p>}
          </div>

          <div className={`${fromStyles.textControl} ${styles.scaledMiddleName} w20`}>
            <label className={fromStyles.inputLabel}>
              {t('form.middleName')}
              <span className={`${fromStyles.infoText} smallText`}> {t('form.optional')}</span>
            </label>
            <input
              className={`${fromStyles.textInput} ${errors.middleName && fromStyles.errorInput}`}
              type="text"
              placeholder={t('form.middleName')}
              name="middleName"
              validator="NAME_PART"
              ref={form.middleName}
              defaultValue={orderData.customerInfo?.secondName}
              onBlur={handleChange}
            />
            {errors.middleName && <p className={`${fromStyles.errorText} smallText`}>{t('form.error.middleName')}</p>}
          </div>

          <div className={`${fromStyles.textControl} w30 w33Resize`}>
            <label className={fromStyles.inputLabel}>{t('form.lastName')}</label>
            <input
              className={`${fromStyles.textInput} ${errors.lastName && fromStyles.errorInput}`}
              type="text"
              placeholder={t('form.lastName')}
              name="lastName"
              validator="NAME_PART"
              validate="true"
              ref={form.lastName}
              defaultValue={orderData.customerInfo?.lastName}
              onBlur={handleChange}
            />
            {errors.lastName && <p className={`${fromStyles.errorText} smallText`}>{t('form.error.lastName')}</p>}
          </div>
        </div>

        <div className={fromStyles.control}>
          <div className={`${fromStyles.textControl} ${styles.scaledInputs} w30`}>
            <label className={fromStyles.inputLabel}>
              {t('form.companyName')} <span className={`${fromStyles.infoText} smallText`}>{t('form.optional')}</span>
            </label>
            <input
              className={`${fromStyles.textInput} w100`}
              type="text"
              placeholder={t('form.companyName')}
              name="companyName"
              ref={form.companyName}
              defaultValue={orderData.customerInfo?.companyName}
              onBlur={handleChange}
            />
          </div>
        </div>

        <div className={fromStyles.control}>
          <div className={`${fromStyles.textControl} ${styles.scaledInputs} w30`}>
            <label className={`${fromStyles.inputLabel}`}>{t('form.email')}</label>
            <input
              className={`${fromStyles.textInput} ${errors.email && fromStyles.errorInput}`}
              type="email"
              placeholder={t('form.email')}
              name="email"
              validator="EMAIL"
              validate="true"
              ref={form.email}
              defaultValue={orderData.customerInfo?.email}
              onBlur={handleChange}
            />
            {errors.email && <p className={`${fromStyles.errorText} smallText`}>{t('form.error.email')}</p>}
          </div>

          <div className={`${fromStyles.textControl} ${styles.scaledInputs} w30`}>
            <label className={`${fromStyles.inputLabel} `}>{t('form.telephone')}</label>
            <input
              className={`${fromStyles.textInput} ${errors.phone && fromStyles.errorInput}`}
              type="text"
              placeholder={t('form.telephone')}
              name="phone"
              validator="PHONE"
              validate="true"
              ref={form.phone}
              defaultValue={orderData.customerInfo?.phone}
              onBlur={handleChange}
            />
            {errors.phone && <p className={`${fromStyles.errorText} smallText`}>{t('form.error.telephone')}</p>}
            <div className={`${fromStyles.infoText} smallText`}>
              <p>{t('form.telephoneInfo.text.row1')}</p>
              <p>{t('form.telephoneInfo.text.row2')}</p>
            </div>
          </div>
        </div>
      </form>
    );
  }

  return <div className={styles.container}>{getPageContent()}</div>;
};

export default OrderOverview;
