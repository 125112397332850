import { useTranslation } from 'react-i18next';
import styles from '../../styles/components/states/ServerError.module.scss';

const ServerError = () => {
  const { t } = useTranslation();
  return <div className={styles.container}>
    <img className={styles.icon} src="/assets/alert.svg" alt="" />
    <div className={styles.messageWrapper}>
      <div className={styles.message}>
        <p>{t('errors.server.row1')}</p>
        <p>{t('errors.server.row2')}</p>
      </div>
    </div>
  </div>;
};
export default ServerError;
