import { CognitoAuth } from 'amazon-cognito-auth-js';
import { CognitoRefreshToken, CognitoUserPool } from 'amazon-cognito-identity-js';
import appConfig from '../config/app-config';

// Get the URI of the Cognito user pool hosted sign in screen
export const getCognitoSignInUri = () => {
  return `${getUserPoolBaseUri()}/login?response_type=code&client_id=${appConfig.clientId}&redirect_uri=${
    appConfig.callbackUri
  }`;
};

// Parse the response from a Cognito callback URI (assumed a token or code is in the supplied href). Returns a promise.
export const parseCognitoWebResponse = (href) => {
  return new Promise((resolve, reject) => {
    const auth = createCognitoAuth();

    // userHandler will trigger the promise
    auth.userhandler = {
      onSuccess: function (result) {
        resolve(result);
      },
      onFailure: function (err) {
        reject(new Error('Failure parsing Cognito web response: ' + err));
      },
    };
    auth.parseCognitoWebResponse(href);
  });
};

// Gets a new Cognito session. Returns a promise.
export const getCognitoSession = () => {
  return new Promise((resolve, reject) => {
    const cognitoUser = createCognitoUser();
    cognitoUser.getSession((err, session) => {
      if (err || !session) {
        reject(new Error('Failure getting Cognito session: ' + err));
        return;
      }
      resolve(mapCognitoSessionToSessionData(session));
    });
  });
};

// Refreshes Cognito session. Returns a promise.
export const refreshCognitoSession = (refreshToken) => {
  return new Promise((resolve, reject) => {
    const cognitoRefreshToken = new CognitoRefreshToken({ RefreshToken: refreshToken });
    const cognitoUser = createCognitoUser();
    cognitoUser.refreshSession(cognitoRefreshToken, (err, refreshedSession) => {
      if (err || !refreshedSession) {
        reject(new Error('Failure while refreshing Cognito session: ' + err));
        return;
      }
      resolve(mapCognitoSessionToSessionData(refreshedSession));
    });
  });
};

const mapCognitoSessionToSessionData = (cognitoSession) => {
  return {
    credentials: {
      accessToken: cognitoSession.accessToken.jwtToken,
      idToken: cognitoSession.idToken.jwtToken,
      refreshToken: cognitoSession.refreshToken.token,
      expires: cognitoSession.idToken.payload.exp,
    },
    user: {
      cognitoUserName: cognitoSession.idToken.payload['cognito:username'],
      email: cognitoSession.idToken.payload.email,
      name: cognitoSession.idToken.payload.name,
    },
    store: {
      id: cognitoSession.idToken.payload['custom:storeAddress'] 
        ? cognitoSession.idToken.payload['custom:storeAddress'].split(' ')[0] 
        : null,
      address: cognitoSession.idToken.payload['custom:storeAddress'] || null,
    },
  };
};

const getUserPoolBaseUri = () => {
  return `https://${appConfig.userPoolDomain}.auth.${appConfig.region}.amazoncognito.com`;
};

const createCognitoAuth = () => {
  const appWebDomain = getUserPoolBaseUri().replace('https://', '').replace('http://', '');
  return new CognitoAuth({
    UserPoolId: appConfig.userPool,
    ClientId: appConfig.clientId,
    AppWebDomain: appWebDomain,
    TokenScopesArray: appConfig.tokenScopes,
    RedirectUriSignIn: appConfig.callbackUri,
    RedirectUriSignOut: appConfig.signoutUri,
  });
};

const createCognitoUser = () => {
  const pool = createCognitoUserPool();
  return pool.getCurrentUser();
};

const createCognitoUserPool = () =>
  new CognitoUserPool({
    UserPoolId: appConfig.userPool,
    ClientId: appConfig.clientId,
  });
