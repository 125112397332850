export const DEFAULT_LANGUAGE = 'nl';
export const DEFAULT_LOCALE = 'nl-NL';
export const COUNTRY_CODE_NL = 'NL';
export const COUNTRY_CODE_BE = 'BE';
export const COUNTRY_CODE_FR = 'FR';
export const DEFAULT_COUNTRY = 'NL';
export const LOCALE_DELIMITER = '-';

export const KEY_CODE_ENTER = 13;
export const ORDER_ID_SEARCH_PARAM = 'orderId';
export const ORDER_ID_PREFIX = '9758';
export const ORDER_ID_LENGTH = 12;
export const CURTAIN_MANUFACTURE_COST_PREFIX = '462';

export const localeStorageObjectKeys = {
  SESSION: 'session',
  LOGGED_IN: 'loggedIn',
  SSO_REDIRECT_DATA: 'ssoRedirectData',
};

export const REFRESH_TOKEN_THRESHOLD_IN_SECONDS = 1800;
export const SET_SESSION_EVENT_NAME = 'set-session';

export const ORDER_COOLDOWN_IN_DAYS = 1;
