import { useContext, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import OrderLookup from './pages/OrderLookup';
import SSOCallback from './pages/SSOCallback';
import AuthContext from './contexts/auth-context';
import OrderOverview from './pages/OrderOverview';
import StoreOrders from './pages/StoreOrders';
import ThankYouPage from './pages/ThankYouPage';
import Receipt from './components/printReceipt/Receipt';
import { hasAuthorizationCodeInUrl, initFromStorage, isLoggedIn, isTokenExpired, setSSORedirectDataAndLogout } from './utils/authUtils';
import Navbar from './components/Navbar';
import PageHolder from './pages/PageHolder';
import NavigationButtons from './components/NavigationButtons';
import { SET_SESSION_EVENT_NAME } from './utils/constants';

const authState = {
  LOGGED_OUT: 'LOGGED_OUT',
  LOGGED_IN: 'LOGGED_IN',
  IN_PROGRESS: 'IN_PROGRESS',
};

let currentAuthState = authState.LOGGED_OUT;

function App() {
  const { dispatch } = useContext(AuthContext);
  const location = useLocation();
  currentAuthState = validateUserPermissions();

  function validateUserPermissions() {
    if (!isLoggedIn() || isTokenExpired()) {
      if (hasAuthorizationCodeInUrl(location) || currentAuthState === authState.IN_PROGRESS) {
        return authState.IN_PROGRESS;
      }
      return authState.LOGGED_OUT;
    }
    return authState.LOGGED_IN;
  }

  useEffect(() => {
    currentAuthState = validateUserPermissions();

    window.addEventListener(SET_SESSION_EVENT_NAME, onSetSessionHandler, true);

    if (currentAuthState === authState.LOGGED_OUT) {
      return setSSORedirectDataAndLogout(location.pathname, location.search, location.hash);
    }

    return () => {
      window.removeEventListener(SET_SESSION_EVENT_NAME, onSetSessionHandler, true);
    };
  });

  function onSetSessionHandler(e) {
    dispatch({ type: 'SET_SESSION', data: e.detail });
  }

  function getPrivateRoutes() {
    initFromStorage();
    return (
      <Switch>
        <Route exact path="/">
          <PageHolder page={<Home />} headerComponent={<Navbar />} navComponent={<NavigationButtons />} showMenuOnMobile={true} />
        </Route>

        <Route path="/search">
          <PageHolder page={<OrderLookup />} headerComponent={<Navbar />} navComponent={<NavigationButtons />} />
        </Route>
        <Route path="/overview/:orderId">
          <PageHolder page={<OrderOverview />} headerComponent={<Navbar />} navComponent={<NavigationButtons />} />
        </Route>
        <Route path="/order-confirmation/:orderId">
          <Receipt />
        </Route>
        <Route path="/thank-you">
          <PageHolder page={<ThankYouPage />} headerComponent={<Navbar />} />
        </Route>
        <Route path="/store/orders">
          <PageHolder page={<StoreOrders />} headerComponent={<Navbar />} navComponent={<NavigationButtons />} />
        </Route>
        <Route path="/callback">
          <SSOCallback />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    );
  }

  function getPublicRoutes() {
    return (
      <Switch>
        <Route path="/callback">
          <SSOCallback />
        </Route>
      </Switch>
    );
  }

  return currentAuthState === authState.LOGGED_IN ? getPrivateRoutes() : getPublicRoutes();
}

export default App;
