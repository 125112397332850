import React, { useEffect } from 'react';
import JsBarcode from 'jsbarcode';

const Barcode = ({ id, value }) => {
  useEffect(() => {
    JsBarcode(`#${id}`, `${value}`, {
      format: 'CODE39',
      width: '1',
      height: '60',
      lineColor: '#777',
    });
  }, [value]);
  return <div>
    <svg id={id}></svg>
  </div>;
};

export default Barcode;
